import { Component } from '@angular/core';

@Component({
  selector: 'app-parth-manufacturing-pvt-ltd',
  templateUrl: './parth-manufacturing-pvt-ltd.component.html',
  styleUrls: ['./parth-manufacturing-pvt-ltd.component.scss']
})
export class ParthManufacturingPvtLtdComponent {

}
