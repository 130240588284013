import { Component } from '@angular/core';

@Component({
  selector: 'app-shree-chamunda-krupa-industry',
  templateUrl: './shree-chamunda-krupa-industry.component.html',
  styleUrls: ['./shree-chamunda-krupa-industry.component.scss']
})
export class ShreeChamundaKrupaIndustryComponent {

}
