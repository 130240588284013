import { Component } from '@angular/core';

@Component({
  selector: 'app-robust-precise-engineering',
  templateUrl: './robust-precise-engineering.component.html',
  styleUrls: ['./robust-precise-engineering.component.scss']
})
export class RobustPreciseEngineeringComponent {

}
