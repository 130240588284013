import { Component } from '@angular/core';

@Component({
  selector: 'app-almond-engineers',
  templateUrl: './almond-engineers.component.html',
  styleUrls: ['./almond-engineers.component.scss']
})
export class AlmondEngineersComponent {

}
