import { Component } from '@angular/core';

@Component({
  selector: 'app-bokhara-industries-pvt-ltd',
  templateUrl: './bokhara-industries-pvt-ltd.component.html',
  styleUrls: ['./bokhara-industries-pvt-ltd.component.scss']
})
export class BokharaIndustriesPvtLtdComponent {

}
