import { Component } from '@angular/core';

@Component({
  selector: 'app-extensive-manufacturing-solutions',
  templateUrl: './extensive-manufacturing-solutions.component.html',
  styleUrls: ['./extensive-manufacturing-solutions.component.scss']
})
export class ExtensiveManufacturingSolutionsComponent {

}
