import { Component } from '@angular/core';

@Component({
  selector: 'app-meedaas-protek-pvt-ltd',
  templateUrl: './meedaas-protek-pvt-ltd.component.html',
  styleUrls: ['./meedaas-protek-pvt-ltd.component.scss']
})
export class MeedaasProtekPvtLtdComponent {

}
