import { Component } from '@angular/core';

@Component({
  selector: 'app-i-fab-engineering-pvt-ltd',
  templateUrl: './i-fab-engineering-pvt-ltd.component.html',
  styleUrls: ['./i-fab-engineering-pvt-ltd.component.scss']
})
export class IFabEngineeringPvtLtdComponent {

}
