<login-home-navigation></login-home-navigation>

<section class="sptb innerpage-margin" style="background-color: #fff;margin-top: 4%;">
  <div class="container ">
    <h1>
      Welcome to our remarkable bidding page, where you can seize the opportunity to win new customers for absolutely free. 
      Our platform is designed to help you effortlessly achieve your business goals, without any investment. So, why wait? 
      Start bidding now and unlock the key to success for your dream business. 
      Don't miss out on this chance to elevate your business to the next level.
    </h1>
  </div>
  <div class="row">
    <!-- <div class="container"> -->
      <div class="col-sm-8 col-md-6 col-lg-6">
        <p><img src="../../../assets/images/media/bid.webp" title="bid" width="100%" height="100%" ></p>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-4">
        <form [formGroup]="firstFormGroup" (ngSubmit)="onSubmit"> 
          
          <div>
            <div class="input-box">
              <label class="control-label form-label" style="margin-left: 1rem; margin-right: 1rem;">Company Name <span style="color: red;">*</span></label>
            </div>
            <div class="row" style="margin-left: 1rem; margin-right: 1rem;" >                                  
              <input type="text" formControlName="company_name" class="form-control" [ngClass]="{ 'is-invalid': next && f.company_name.errors }" />
                <div style="text-align: left;" *ngIf="next && f.company_name.errors" class="invalid-feedback">
                  <div *ngIf="f.company_name.errors.required">Company Name is required</div>
                  <div *ngIf="f.company_name.errors.pattern">Enter Valid Company Name </div>
                </div>
            </div>
          </div>
      
          <div>
           <div class="input-box">
            <label class="control-label form-label"style="margin-left: 1rem; margin-right: 1rem;" >Phone<span style="color: red;">*</span></label>
           </div>
          <div class="row"style="margin-left: 1rem; margin-right: 1rem;" >                                  
            <input type="number" formControlName="phone" onKeyPress="if(this.value.length==15) return false;" size="10" class="form-control"size="10" maxlength="10" [ngClass]="{ 'is-invalid': next && f.phone.errors }" />
              <div style="text-align: left;" *ngIf="next && f.phone.errors" class="invalid-feedback">
                <div *ngIf="f.phone.errors.required">PhoneNo is required</div>
                <div *ngIf="f.phone.errors.pattern">PhoneNo must be upto 10 digit only </div>
              </div>
          </div>
          </div>

          <div>
          <div class="input-box">
            <label class="control-label form-label"style="margin-left: 1rem; margin-right: 1rem;" >Email Id<span style="color: red;">*</span></label>
          </div>
         <div class="row"style="margin-left: 1rem; margin-right: 1rem;" >
          <input type="text" formControlName="email" class="form-control" type="email" [ngClass]="{ 'is-invalid': next && f.email.errors }" />
           <div style="text-align: left;" *ngIf="next && f.email.errors" class="invalid-feedback">
             <div *ngIf="f.email.errors.required">Email Id is required</div>
             <div *ngIf="f.email.errors.pattern">Enter Valid Email Id</div>
           </div>
        </div>
        </div>

        <div class="row col-sm-12 col-md-12 col-lg-12" style="padding-top: 6%;">
          <div class="col-sm-12 col-md-6 col-lg-6">   
          <label class="control-label form-label" style="margin-left:2%;">Do you have company profile ?<span style=" color: red;">*</span></label>
          </div>            
          <div class="col-sm-12 col-md-2 col-lg-2">
          <label class="custom-control custom-radio"> 
                <input type="radio" class="custom-control-input" (change)="checkradio($event)"  formControlName="profile" value="yes" checked [ngClass]="{ 'is-invalid': next && f.profile.errors }">
                <span class="custom-control-label"><b>Yes</b></span>
          </label>
          </div>
          <div class="col-sm-12 col-md-4 col-lg-4">
          <label class="custom-control custom-radio">
                <input type="radio" class="custom-control-input" (change)="checkradio($event)"  formControlName="profile" value="no" checked [ngClass]="{ 'is-invalid': next && f.profile.errors }">
                <span class="custom-control-label"><b>No</b></span>
          </label>
          </div>
        </div>

        <div class="row"style="padding-top: 2rem; margin-left: 1rem; margin-right: 1rem;">
          <div  *ngIf="f.profile.value === 'yes'">
            <label style="margin-right: 1rem;">Upload Company Profile:</label>
            <button class="file-upload-button1">
              <input type="file" #fileInput style="width: 100%; height: 100%;" (change)="onFileChange($event)"/>          
            </button>
            <span>{{filename}}
              <div style="margin-left:1rem;" class="row">
                (File size limit upto 3MB)
              </div>
            </span>
          </div>
        </div>

            <div class="example-button-row pull-right" style="padding-top: 2rem;">
              <button mat-raised-button class="nextbtn ml-5" [disabled]="disable" (click)="onSubmit()">Submit</button>
            </div>
            
      </form>
      </div>
    <!-- </div>   -->

    

  </div>

</section>



<login-home-footer></login-home-footer>

