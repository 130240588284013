import { Component } from '@angular/core';

@Component({
  selector: 'app-shelar-engitech-pvt-ltd',
  templateUrl: './shelar-engitech-pvt-ltd.component.html',
  styleUrls: ['./shelar-engitech-pvt-ltd.component.scss']
})
export class ShelarEngitechPvtLtdComponent {

}
