import { Component, Inject, OnInit, Output } from '@angular/core';
import { SeoService } from '../service/seo.service';
import { GlobalComponent } from '../global/global.component';
import {MatSliderModule} from '@angular/material/slider';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthenticationService } from '../service/authentication.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';

export interface DialogData {
  budget:any;
}

@Component({
  selector: 'app-new-pricing-page',
  templateUrl: './new-pricing-page.component.html',
  styleUrls: ['./new-pricing-page.component.scss']
})
export class NewPricingPageComponent implements OnInit {
  
  amount : number = 0;
  user : number = 0;
  value: number = 1;
  finalvalue: number= 5000;
  
  formatLabel(value: number | null) {
        if (!value) {
          return 0;
        }
        this.value = value;
        //console.log(this.value);

        return value + 'k';
      }  
  
  @Output() valueChange;
  onChange(data){
      this.user = data;
      this.amount = this.user + 5;
      this.finalvalue = this.user * 1000;
  }

  openDialog(): void {
    if(this.finalvalue < 5000){
      this.finalvalue = 0;
      Swal.fire('Minimum Rs.5000 budget is required to plan the membership.', '', 'warning');
    }
    if(this.finalvalue > 100000){
      this.finalvalue = 0;
      Swal.fire('Maximum Rs.100000 budget is allowed to plan the membership.', '', 'warning');
    }
    if(this.finalvalue > 4999 && this.finalvalue <100001){
      const dialogRef = this.dialog.open(PricingLeadComponent, {
        width: '500px',
        data: {budget: this.finalvalue}
      }     
      ); 
    } 
  }

  hostName = GlobalComponent.hostName;  

  constructor(private SEOService:SeoService,
    public dialog: MatDialog,    
  ) { }

  ngOnInit(){  
  this.SEOService.updateCanonicalUrl(this.hostName + '/newpricingpage');
  window.scrollTo(0, 0);
  this.finalvalue = 0;
  }
}

@Component({
  selector: 'app-pricing_lead',
  templateUrl: './pricing_lead.component.html',
  styleUrls: ['./new-pricing-page.component.scss']
  
})
export class PricingLeadComponent implements OnInit {
  leadForm: UntypedFormGroup;
  submitted = false;
  budget: any;
  loading: boolean = false;

  constructor(public dialogRef: MatDialogRef<PricingLeadComponent>,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private router: Router,
    public toastr: ToastrManager) {}

    ngOnInit() {
      this.budget = this.data.budget;
        this.leadForm = this.formBuilder.group({
            company_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
        });
    }
  
    // convenience getter for easy access to form fields
    get f() { return this.leadForm.controls; }
  
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.leadForm.invalid) {
            return;
        }
        this.loading = true;
  
        //console.log(this.f.email.value);
          this.authenticationService.create_lead(this.f.company_name.value, this.f.email.value, this.f.phone.value, this.budget)
              .pipe(first())
              .subscribe(
                  data => {
                    //console.log(data);
                    //this.toastr.successToastr("Thank You!", 'Our Team will call you within 24 hours!');
                    this.loading = false;

                    if(data.message == 'Email exists!'){
                      //Swal.fire('We have already received your enquiry.Please check your inbox for the confirmation. If you have not received it, please send an email to contact@getyourvendor.com. ', '', 'error');
                      Swal.fire({
                        title: '',
                        icon: 'error',
                        text: 'We have received your inquiry. Check your inbox for confirmation. If not received, email contact@getyourvendor.com.',
                        confirmButtonText: 'Ok',
                      }).then((result) => {
                        if (result.value) {
                          this.dialogRef.close();                          
                        }
                      });
                    } 
                    
                    if(data.message == 'Received!'){
                      Swal.fire({
                        title: 'Thank you!',
                        icon: 'success',
                        text: 'We have received your enquiry. Check your inbox for confirmation. If not received, email contact@getyourvendor.com.',
                        confirmButtonText: 'Ok',
                      }).then((result) => {
                        if (result.value) {
                          this.dialogRef.close();
                          this.router.navigate(['/']);
                        }
                      }); 
                    }                   
                  },
                  error => {
                  });
    }
}