import { Component } from '@angular/core';

@Component({
  selector: 'app-drastik-engineering-company',
  templateUrl: './drastik-engineering-company.component.html',
  styleUrls: ['./drastik-engineering-company.component.scss']
})
export class DrastikEngineeringCompanyComponent {

}
