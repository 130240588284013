/*import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-new-signin-form',
  templateUrl: './new-signin-form.component.html',
  styleUrls: ['./new-signin-form.component.scss']
})
export class NewSigninFormComponent {

newSigninForm = new FormGroup ({
  Password:new FormControl(),
  ConfirmPassword:new FormControl(),
 })
 
 SignIn()
 {
   console.warn(this.newSigninForm.value)
 }
}*/


import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-signin-form',
  templateUrl: './new-signin-form.component.html',
  styleUrls: ['./new-signin-form.component.scss']
})
export class NewSigninFormComponent implements OnInit {

  secondFormGroup: FormGroup;
  agreeTerms: any;
  step2:boolean = false;
  show: boolean = false;
  show1: boolean = false;
  password = 'password';
  password1 = 'password';
  mycaptcha: boolean = true;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    ) {
    //   this.secondFormGroup = this.formBuilder.group({
    //   mycaptcha1: ['', Validators.required], // Assuming this is your captcha form control
    // });
  }

  ngOnInit() {
    this.secondFormGroup = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirm_password: ['', [Validators.required]],
      mycaptcha1: ['', [Validators.required, Validators.pattern("true")]],
      agreeTerms : [false, [Validators.requiredTrue]],
    }, {
      validators: this.mustMatch('password', 'confirm_password')
    });
  }

  // Define a getter for easy access to form controls
  get f2() {
    return this.secondFormGroup.controls;
  }

  // Function to toggle password visibility
  /*onClick() {
    this.show = !this.show;
  }

  onClick1() {
    this.show1 = !this.show1;
  }*/

  validatePassword() {
    if (this.show1) {
    
      if (this.f2.password.value === '' || this.f2.password.value.length < 6) {
    
        return false;
      }
    }
  
    return true;
  }
  // Function to handle captcha resolution
  resolved(captchaResponse: string) {

    this.mycaptcha = true;
    this.secondFormGroup.patchValue({
      mycaptcha1: "true"
   });
    ////console.log(`Resolved response token: ${captchaResponse}`);
   
  }
  // Function to validate password confirmation
  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  
  listener(ev){
    // console.log(ev.currentTarget.checked);
     this.agreeTerms = ev.currentTarget.checked; 

   }

   
   onClick() {
    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  onClick1() {
  //  console.log(this.password1);
    if (this.password1 === 'password') {
      this.password1 = 'text';
      this.show1 = true;
    } else {
      this.password1 = 'password';
      this.show1 = false;
    }
  }

  onSubmit() {
    this.step2 = true;
    
    if (this.secondFormGroup.invalid) {
      return;
    }
    if(this.mycaptcha!=true){
      this.mycaptcha = false;
      return;
    }
    if(this.agreeTerms != true)
    {
      this.agreeTerms = false;
      return;
    }

    this.router.navigate(['/login', 'signup2'])
  
  }

  checkCaptcha() {
    return this.secondFormGroup.controls['mycaptcha1'].valid;
  }
  
  }


