import { Component } from '@angular/core';

@Component({
  selector: 'app-creative-tools-and-components',
  templateUrl: './creative-tools-and-components.component.html',
  styleUrls: ['./creative-tools-and-components.component.scss']
})
export class CreativeToolsAndComponentsComponent {

}
