import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { GlobalComponent } from 'src/app/global/global.component';
import { SeoService } from 'src/app/service/seo.service';

@Component({
  selector: 'login-customfg',
  templateUrl: './customfg.component.html',
  styleUrls: ['./customfg.component.scss']
})
export class CustomfgComponent implements OnInit {

  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }

  ngOnInit(){

  this.SEOService.updateCanonicalUrl(this.hostName + '/blog/Custom-Manufacturing-The-USP-of-distinctive-brands');
  window.scrollTo(0, 0);

  }

}
