import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MetaDefinition } from '@angular/platform-browser';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import { GlobalComponent } from 'src/app/global/global.component';
import { User } from 'src/app/model/user.model';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { SeoService } from 'src/app/service/seo.service';
import { environment } from 'src/environments/environment';



@Component({
  selector: 'app-landingpages',
  templateUrl: './landingpages.component.html',
  styleUrls: ['./landingpages.component.scss'],
  

})
export class LandingpagesComponent implements OnInit {

  rfqloader: boolean;
  activerfqall: any;
  offset: any;
  closeDate: Date;
  closeMonth: number;
  message: any = "";
  currentUser: User;
  myemail: any;
  hostName = GlobalComponent.hostName;
  title = "Get Your Vendor -Best B2B Manufacturing Facility Search Portal - Home";
  metaTags=[
    { name: 'description', content: 'Get Your Vendor the best b2b manufacturing marketplace connects vendors and buyers towards facilitating manufacturing multiple categories, which would streamline into global sourcing and custom manufacturing.'},
    { name:'robots', content:'index, follow'},
    {name:'keywords',content:'custom-manufacturing,component'},
  ];

  constructor(  private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    public toastr: ToastrManager,private SEOService:SeoService) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
     }
    
  ngOnInit() {
    window.scroll(0,0);   

     this.SEOService.updateCanonicalUrl(this.hostName);
     this.SEOService.updateTitle(this.title);
     this.SEOService.updateMetaTags(this.metaTags);

    
    
     // Set the date we're counting down to
     var countDownDate = new Date("May 29, 2024 17:00:00").getTime();
     
     // Update the count down every 1 second
     var x = setInterval(function() {
     
       // Get today's date and time
       var now = new Date().getTime();
     
       // Find the distance between now and the count down date
       var distance = countDownDate - now;
     
       // Time calculations for days, hours, minutes and seconds
       var days = Math.floor(distance / (1000 * 60 * 60 * 24));
       var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
       var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
       var seconds = Math.floor((distance % (1000 * 60)) / 1000);
     
       // Display the result in the element with id="demo"
       document.getElementById("demo").innerHTML = days + "d " + hours + "h "
       + minutes + "m " + seconds + "s ";
     
       // If the count down is finished, write some text
       if (distance < 0) {
         clearInterval(x);
         document.getElementById("demo").innerHTML = "EXPIRED";
       }
     }, 1000);
     
  }

  openDialog(): void {
    this.dialog.open(DemoComponent, {
      width: '500px',
      
    }  
    
    );

  
  }

}


@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./landingpages.component.scss']
  
})
export class DemoComponent implements OnInit {
  leadForm: UntypedFormGroup;
  submitted = false;

  constructor(public dialogRef: MatDialogRef<DemoComponent>,
    private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    public toastr: ToastrManager) {}

    ngOnInit() {
        this.leadForm = this.formBuilder.group({
            company_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
        });
    }
  
    // convenience getter for easy access to form fields
    get f() { return this.leadForm.controls; }
  
    onSubmit() {
        this.submitted = true;
        // stop here if form is invalid
        if (this.leadForm.invalid) {
            return;
        }
  
        //console.log(this.f.email.value);
          this.authenticationService.create_lead(this.f.company_name.value, this.f.email.value, this.f.phone.value, 0)
              .pipe(first())
              .subscribe(
                  data => {
                    //console.log(data);
                    this.toastr.successToastr("Thank You!", 'Our Team will call you within 24 hours!');
                  },
                  error => {
                  });
    
        this.dialogRef.close();
         
    }
}

