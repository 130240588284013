import { Component } from '@angular/core';

@Component({
  selector: 'app-ultra-corpotech-pvt-ltd',
  templateUrl: './ultra-corpotech-pvt-ltd.component.html',
  styleUrls: ['./ultra-corpotech-pvt-ltd.component.scss']
})
export class UltraCorpotechPvtLtdComponent {

}
