import { Component } from '@angular/core';

@Component({
  selector: 'app-mm-technologies',
  templateUrl: './mm-technologies.component.html',
  styleUrls: ['./mm-technologies.component.scss']
})
export class MmTechnologiesComponent {

}
