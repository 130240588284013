import { Component } from '@angular/core';

@Component({
  selector: 'app-press-metal-industries',
  templateUrl: './press-metal-industries.component.html',
  styleUrls: ['./press-metal-industries.component.scss']
})
export class PressMetalIndustriesComponent {

}
