import { Component } from '@angular/core';

@Component({
  selector: 'app-pooja-engineering-works',
  templateUrl: './pooja-engineering-works.component.html',
  styleUrls: ['./pooja-engineering-works.component.scss']
})
export class PoojaEngineeringWorksComponent {

}
