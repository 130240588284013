import { Component } from '@angular/core';

@Component({
  selector: 'app-sona-industries',
  templateUrl: './sona-industries.component.html',
  styleUrls: ['./sona-industries.component.scss']
})
export class SonaIndustriesComponent {

}
