
    <login-home-navigation></login-home-navigation>


<section class="sptb innerpage-margin" style="background-color: #fff;"  #blog_budget >
    <div class="container">

        <div class="row">
        
            <div class="col-sm-12 col-lg-12 col-md-12">
                <!--Itemsd lists-->
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12">
                        <div class="card blog-detail">
                            <div class="card-body">
								<h2 class="fs-18">Budget-2021: What does it have in store for the Manufacturing Industry?</h2>
								<a class="fa fa-arrow-left" routerLink = "/blog" > Go Back to Blog List</a>
								<div class="item7-card-desc d-flex mb-5">
									<a href="#" class="text-muted"><i class="fa fa-calendar-o mr-2"></i> FEBRUARY 24, 2021</a>
									
									
								</div>
								<div>
								<img src="assets/images/media/Budget_2021.webp" alt="budget" width="50%" height="50%">
								<p> The wave of COVID-19 carved a massive dent in the growth of multiple industries - the Manufacturing industry being one of the most affected ones. 
									The series of lockdowns resulted in a sudden stop on production, demand, and supply chains – the three foundational pillars of the Manufacturing industry!</p>
								<p>This demand and supply chain disruption created several problems, all at the same time. The primary obstacle was the delayed deliveries, and hence the delayed 
									project timelines. Most manufacturers strived to maintain production levels, hoping that business will get back to normal in a matter of 2-3 months. However, to maintain production in the face of supply chain disruption, manufacturers had to turn to alternative suppliers, leading to increased costs.</p>
								<p>Times were uncertain, businesses were on the brink of oblivion and there was no solution in sight – in such turbulent circumstances, all eyes were on Budget-2021 in hopes that it would help the Manufacturing industry get back on its feet. This what some of the major players in the sector expected from the Union Budget-2021-</p>
								<p><strong>Dinesh Aggarwal, Joint Managing Director, Panasonic Life Solutions India</strong></p>
								<p>“Sustained investment in Infrastructure by the government, constant effort to introduce FDI in manufacturing including electronic components and sub-assemblies, and conscious support for the real estate developers through priority lending; all of these will assist in stimulating the economy, which is still largely dependent 
									on home demand. Also, commodity prices in the past 8 months have gone out of control and are unpredictable. It is expected that some strict measures are taken to diffuse such situations in the near future as it leads to dilution of business confidence.”</p>
								<p><strong>Bala Sarda, CEO and Founder VAHDAM India</strong></p>
								<p>“As an entrepreneur, I expect a reduction in Long term Capital Gains Tax on Private Equity and making it at par with the public market. ‘You pay 10 percent tax on investments in stock markets but an entrepreneur pays 27-28 percent tax on a business he has built from scratch’. There should be stronger subsidies on the import of 
									capital goods used for core manufacturing and value addition for exports. The Budget can look into widening the ambit of Special Start-up manufacturing zones for companies or start-ups which want to foray into manufacturing. This would give the government’s “Vocal for Local” initiative a timely fillip.”</p>
								<p><strong>Rajesh Uttamchandani, Director, Syska Group</strong></p>
								<p>“We believe the government can offer support to the manufacturing industry through tax reforms and by supporting the expansion of distribution and supply chains. Further, the government could also look at mitigating the legal procedures involved in establishing manufacturing facilities in the country, under its ‘Atmanirbhar Bharat’ initiative. 
									Last year, the production incentive scheme (PLI) scheme was announced with an aim to offer opportunities for home-grown and international electronic businesses to improve their manufacturing competencies in India. We are confident that the government will consider this sentiment and enable India-based organizations to avail of benefits that 
									will help them establish a strong foothold from a manufacturing perspective.”</p>
								<p><strong>Prakash Chhabria, Chairman, Finolex Industries Limited</strong></p>
								<p>“We hope Budget 2021 boosts domestic manufacturing across sectors, for a self-reliant economy. We look forward to announcements that will lay emphasis on the progress of the overall agriculture economy. Also, we strongly believe that the government’s objective to double farmer’s income and improve piped water coverage by 2022 is most likely 
									to strengthen both plumbing and agri pipe demand in the near future. The flagship government scheme, Nal se Jal scheme appears to be a major driver of plumbing pipe demand.”</p>
								<p>As Finance Minister Nirmala Sitharaman went on to announce the budget, the industry was looking forward to finding some relief from a toll-taking year and recovering from the damage caused by COVID-19. Let’s find out if the Budget-2021 lived up to the expectations of the industry. Here are some of the major takeaways from the Budget-2021 for the Manufacturing Sector:</p>
								<p><strong>PLI Scheme For 13 Sectors-</strong></p>
								<p>The PLI scheme will play a heroic role in accelerating the growth of the industry. It has the potential to create approximately 1.40 crore man-months of additional workers. This will result in doubling the workforce engaged in production and manufacturing activities, enabling manufacturing companies to become an integral part of the global supply chains in core competency and cutting-edge technology.</p>
								<p>According to the scheme, foreign companies will be welcomed to set up manufacturing units in the country. Moreover, the local companies will also be provided with opportunities to expand production and manufacturing in India. Incentives will be given to companies on the incremental sale of products manufactured in the country. These measures will make a big difference as they will ultimately help cut 
									down on import bills, reduce dependency on China and also make jobs available for the country’s growing workforce.</p>
								<p><strong>Voluntary Vehicle Scrapping Policy-</strong></p>
								<p>This policy will pave the way for fuel-efficient and sustainable vehicles. This will in turn reduce vehicular air pollution and oil import bills. According to the proposal to levy a ‘green tax’ on old transport vehicles, transport vehicles older than 8 years can be charged a green tax at the rate of 10-25% of road tax at the time of renewal of fitness certificate.</p>
								<p>This will not only do more good to the environment but also make vehicles more affordable for consumers. The metal from scrapped old vehicles will be reused by automakers and will bring down the manufacturing cost of new vehicles. It’s highly probable that this cost reduction will be transferred to consumers in the form of reduced prices for new vehicles.</p>
								<p>With Budget-2021 on its side, the Manufacturing industry is set to march on the recovery path with confident steps. The mission now is to not only survive but thrive despite the hurdles.</p>
                                <p><a href="login/signup" ><button class="fbutton">SignUp Now</button></a></p>
							</div>
							<p> <a href="/blog" > << Go Back to Blog Home</a> </p>
							</div>
						</div>
                    </div>
                   
                </div>
                <div class="center-block text-center">
                </div>
                <!--/Itemsd lists-->
            </div>
            <!--Right Side Content-->
         
            <!--/Right Side Content-->
        </div>
    </div>
</section><!--/Section-->

<login-home-footer></login-home-footer>