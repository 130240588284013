import { Component } from '@angular/core';

@Component({
  selector: 'app-shrivin-technologies-llp',
  templateUrl: './shrivin-technologies-llp.component.html',
  styleUrls: ['./shrivin-technologies-llp.component.scss']
})
export class ShrivinTechnologiesLlpComponent {

}
