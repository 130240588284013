import { Component } from '@angular/core';

@Component({
  selector: 'app-essae-gears-and-transmissions-pvt-ltd',
  templateUrl: './essae-gears-and-transmissions-pvt-ltd.component.html',
  styleUrls: ['./essae-gears-and-transmissions-pvt-ltd.component.scss']
})
export class EssaeGearsAndTransmissionsPvtLtdComponent {

}
