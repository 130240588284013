import { Component } from '@angular/core';

@Component({
  selector: 'app-aps-tech-enterprises',
  templateUrl: './aps-tech-enterprises.component.html',
  styleUrls: ['./aps-tech-enterprises.component.scss']
})
export class ApsTechEnterprisesComponent {

}
