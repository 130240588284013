import { Component } from '@angular/core';

@Component({
  selector: 'app-jagdish-precision',
  templateUrl: './jagdish-precision.component.html',
  styleUrls: ['./jagdish-precision.component.scss']
})
export class JagdishPrecisionComponent {

}
