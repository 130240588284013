import { Component } from '@angular/core';

@Component({
  selector: 'app-speed-craft-industries',
  templateUrl: './speed-craft-industries.component.html',
  styleUrls: ['./speed-craft-industries.component.scss']
})
export class SpeedCraftIndustriesComponent {

}
