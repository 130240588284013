<div class="row">
<div class="col-6"><h1 mat-dialog-title>Call Back</h1></div>
<div class="col-6">
<button class="fe fe-x close_button pull-right" mat-button mat-dialog-close></button>
</div>
</div>

<div mat-dialog-content>
    <div class="single-page">
    <div class="wrapper wrapper2">
    <form [formGroup]="leadForm" (ngSubmit)="onSubmit()" class="card-body" >
        <div class="mail">
            <input type="text" formControlName="company_name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.company_name.errors }" />
            <label>Company Name</label>
            <div style="text-align: left;" *ngIf="submitted && f.company_name.errors" class="invalid-feedback">
                <div *ngIf="f.company_name.errors.required">Company Name is required</div>
            </div>
        </div>
        <div class="mail">
            <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <label>Email</label>
            <div style="text-align: left;" *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email id is required</div>
                <div *ngIf="f.email.errors.email">Must be a valid email id</div>
            </div>
        </div>
        <div class="mail">
            <input type="number" formControlName="phone" onKeyPress="if(this.value.length==15) return false;" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
            <label>Phone</label>
              <div style="text-align: left;" *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                <div *ngIf="f.phone.errors.required">Phone is required</div>
                <div *ngIf="f.phone.errors.minlength">Enter 10 digit Phone No.</div>
                <div *ngIf="f.phone.errors.pattern">Enter numbers only</div>
              </div>            
        </div>
        
        

   

        
<br>
        <div class="submit">
            <button class="btn btn-primary btn-block">
                Submit
            </button>
        </div>   
        
    </form>
    
    </div>
    </div>
</div>