import { Component } from '@angular/core';

@Component({
  selector: 'app-mahavir-engineering',
  templateUrl: './mahavir-engineering.component.html',
  styleUrls: ['./mahavir-engineering.component.scss']
})
export class MahavirEngineeringComponent {

}
