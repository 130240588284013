import { Component } from '@angular/core';

@Component({
  selector: 'app-shree-swami-samarth-engineering',
  templateUrl: './shree-swami-samarth-engineering.component.html',
  styleUrls: ['./shree-swami-samarth-engineering.component.scss']
})
export class ShreeSwamiSamarthEngineeringComponent {

}
