<!-- <script async src="https://www.googletagmanager.com/gtag/js?id=UA-177822249-1"></script>
  <script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'UA-177822249-1');
  </script> -->
  <login-home-navigation></login-home-navigation>

<!--Contact-->
<section class="sptb" style="background-color: #fff;">
    <div class="container">
        <div class="row">          
        <!-- <div class="col-md-3"></div>   
        <div class="col-md-5"> -->
            <div class="table-responsive">
                <div class="single-page" style="height: 100vh">                    
                <div class="col-lg-6 col-sm-4 col-md-6 d-block mx-auto">
                    <div *ngIf="currentUser">
                        <button type="button" class="btn btn-info btn-sm" (click)="back()"  style="margin: 2px;color: #fff; 
                        background-color: #979b9f; border: none;" title="Back">Go to Previous Page</button>                        
                    </div>
                    <div class="wrapper wrapper2 login-box-padding2">
                       <h1 style="color: white; background-color:#1e5f80" *ngIf="rfq">{{rfq.category}} RFQ- {{rfq.id}} </h1>
                       <button *ngIf="rfq && rfq.rfq_status == 'closed'" type="button" style="margin: 2px;color: #fff;
                                    background-color: #ee700a; box-shadow: 0 5px 10px rgba(1, 210, 119, 0.3);" 
                                    class="btn btn-info btn-sm"><i class="icon icon-pencil"></i> RFQ Closed </button> 
                                    <button *ngIf="rfq_expired == 'yes'" type="button" style="margin: 2px;color: #fff;
                                    background-color: #ee700a;box-shadow: 0 5px 10px rgba(1, 210, 119, 0.3);" class="btn btn-info btn-sm"><i class="icon icon-pencil"></i> RFQ Expired </button>
                    <table  class="table card-table table-bordered table-hover table-vcenter mb-0 text-nowrap">
                        <!--<table  class="table card-table table-bordered table-hover table-vcenter mb-0">-->
                        <tbody *ngIf="rfq"> 
                            <!-- <tr>
                                <th colspan="2" style="text-transform: capitalize;width: 100px;border: 1px solid #525252;text-align: center;">{{rfq.category}} RFQ-{{rfq.id}}</th>
                            </tr> -->

                            <tr>
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">RFQ Name</th>
                                <td style="white-space: normal; border: 1px solid #bfbfbf;border: 1px solid #525252;">{{rfq.rfq_name}}</td>
                            </tr>
                        
                            <tr>
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">Category</th>
                                <td style="white-space: normal; border: 1px solid #bfbfbf;border: 1px solid #525252;">{{rfq.category}}</td>
                            </tr>
                            <tr *ngIf="rfq.rfq_form_id == 'RFQ1'">
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">Material</th>
                                <td style="white-space: normal; border: 1px solid #bfbfbf;border: 1px solid #525252;">{{rfq.field9_value}}</td>
                            </tr>
                            <tr *ngIf="rfq.rfq_form_id == 'RFQ2'">
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">Material</th>
                                <td style="white-space: normal; border: 1px solid #bfbfbf;border: 1px solid #525252;">{{rfq.field5_value}}</td>
                            </tr>
                            <tr *ngIf="rfq.rfq_form_id == 'RFQ1' || rfq_form_id == 'RFQ5'">
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">Quantity</th>
                                <td style="white-space: normal; border: 1px solid #bfbfbf;border: 1px solid #525252;">{{rfq.field4_value}}</td>
                            </tr>
                            <tr *ngIf="rfq.rfq_form_id == 'RFQ2'">
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">Quantity</th>
                                <td style="white-space: normal; border: 1px solid #bfbfbf;border: 1px solid #525252;">{{rfq.field8_value}}</td>
                            </tr>
                            <tr *ngIf="rfq.rfq_form_id == 'RFQ6'">
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">Quantity</th>
                                <td style="white-space: normal; border: 1px solid #bfbfbf;border: 1px solid #525252;">{{rfq.field12_value}}</td>
                            </tr>
                            <tr>
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">Offer Closing Date</th>
                                <td style="white-space: normal; border: 1px solid #bfbfbf;border: 1px solid #525252;">{{rfq.submission_date}}</td>
                            </tr>
                            <tr>
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">RFQ Type</th>
                                <td style="white-space: normal; border: 1px solid #bfbfbf;border: 1px solid #525252;">{{rfq.rfq_type}}</td>
                            </tr>
                           

                            <tr *ngIf="currentUser;else signup2">
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">RFQ Drawing</th>
                                <td  style="white-space: normal; border: 1px solid #bfbfbf;border: 1px solid #525252;"><!--<a href="/dashboard/contact_sales/rfqId" >--><a [routerLink]="['/dashboard', 'contact_sales', rfq.id]" target="_blank" >View Drawing</a></td> 
                            </tr>
                            
                            <ng-template #signup2>
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">RFQ Drawing</th>
                                <td style="white-space: normal; border: 1px solid #bfbfbf;border: 1px solid #525252;"><a [routerLinkActive]="'active'" routerLink="/login/signup" target="_blank" >View Drawing</a></td> 
                            </ng-template>
                         
                            <tr>
                                <th style="text-transform: capitalize;width: 100px;border: 1px solid #525252;">Special Instructions</th>
                                <td style="white-space: normal;border: 1px solid #bfbfbf;border: 1px solid #525252;">{{rfq.instructions}}</td>
                            </tr>


                        </tbody>


                    </table>
                    <div class="row" style="margin: 2px;">
                        <span style="color: red;">*</span><span style="padding: 0; color: black" class="col-11">To view more details, please contact Get Your Vendor team at +91 7385222083.</span>
                    </div>
                    </div>
                </div>
                
            </div>
            
        </div>
        </div>
    </div>
</section>


<login-home-footer></login-home-footer>
