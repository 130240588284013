import { Component } from '@angular/core';

@Component({
  selector: 'app-parishram-engineers',
  templateUrl: './parishram-engineers.component.html',
  styleUrls: ['./parishram-engineers.component.scss']
})
export class ParishramEngineersComponent {

}
