import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { GlobalComponent } from 'src/app/global/global.component';
import { SeoService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-procurement',
  templateUrl: './procurement.component.html',
  styleUrls: ['./procurement.component.scss']
})
export class ProcurementComponent implements OnInit {

  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.SEOService.updateCanonicalUrl(this.hostName + '/blog/Why-Procurement-Needs-To-Look-Beyond-The-Traditional-Methods');
  }

}
