import { Component } from '@angular/core';

@Component({
  selector: 'app-precitech-forgings-and-engineering-pvt-ltd',
  templateUrl: './precitech-forgings-and-engineering-pvt-ltd.component.html',
  styleUrls: ['./precitech-forgings-and-engineering-pvt-ltd.component.scss']
})
export class PrecitechForgingsAndEngineeringPvtLtdComponent {

}
