import { Component } from '@angular/core';

@Component({
  selector: 'app-shakalya-enterprises',
  templateUrl: './shakalya-enterprises.component.html',
  styleUrls: ['./shakalya-enterprises.component.scss']
})
export class ShakalyaEnterprisesComponent {

}
