import { Component } from '@angular/core';

@Component({
  selector: 'app-swara-industries',
  templateUrl: './swara-industries.component.html',
  styleUrls: ['./swara-industries.component.scss']
})
export class SwaraIndustriesComponent {

}
