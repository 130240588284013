<div class="banner-1 cover-image bg-background1" data-image-src="assets/images/banners/banner4.jpg">
  <login-home-navigation></login-home-navigation>
<!-- ///REMOVE THIS COMP -->
</div>

    <div class=" row col-md-12">
     <div class="col-md-5">
       
       <div  style=" padding-top:15rem; text-align:center;">
       <img src="assets/images/brand/logo.webp" height="90px" width="212px">
       </div>
     
     <div style="vertical-align:top; text-align:center;">
       <h1><b>
       India's
       Fastest
       Growing 
       Manufacturing
       B2B 
       Marketplace
       </b></h1>
       </div>
     </div>
     
     <div style="text-align:center; padding-top:8rem;"   class="row col-md-7">
     
       <div class="col-md-12" style="text-align:center;">
       <h2 class="xl col-lg-9"><b>Free Trial</b></h2>
       </div>
       
     <div style="text-align:center; margin-top: 1rem;" class="row col-md-12">
       <div class="col-md-2"></div>
       <div style="margin-bottom:4rem;" class="col-md-6">
     
   <mat-card class="mat-card1">  
  
    <mat-card-content>

      <form [formGroup]="secondFormGroup" (ngSubmit)="onSubmit">
        

          <div style="margin-top: 8px; margin-left: 1rem; margin-right: 1rem; text-align: left;" class="input-box">
            <label>Password<span style="color: red;">*</span></label>
            <div class="col-md-11">
              <button (click)="onClick()" class= "button1" type="button">
                <i class="fa fa-eye" aria-hidden="true" *ngIf="show"></i>
                <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!show"></i>
              </button>
            </div>
          </div>                    
            <input [type]="password" formControlName="password" class="form-control" size="8" maxlength="16" [ngClass]="{ 'is-invalid': step2 && f2.password.errors }"/>
              <div style="text-align: left;" *ngIf="step2 && f2.password.errors" class="invalid-feedback">
                <div *ngIf="f2.password.errors.required">password is required</div>
                <div *ngIf="f2.password.errors.minlength">Password must be at least 6 characters</div>
              </div>
         
          
          
        
          <div style="margin-top: 8px; margin-left: 1rem; margin-right: 1rem; text-align: left;" class="input-box">
            <label class="control-label form-label">Confirm Password<span style="color: red;">*</span></label>
            <div class="col-md-11">
              <button (click)="onClick1()" class= "button1" type="button">
                <i class="fa fa-eye" aria-hidden="true" *ngIf="show1"></i>
                <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="!show1"></i>
              </button>
            </div>
          </div>
          <input [type]="password1" formControlName="confirm_password" class="form-control" size="8" maxlength="16" [ngClass]="{ 'is-invalid': step2 && f2.confirm_password.errors }"/>
          <div style="text-align: left;" *ngIf="step2 && f2.confirm_password.errors" class="invalid-feedback">
            <div *ngIf="f2.confirm_password.errors.required">Confirm Password is required</div>
            <div *ngIf="f2.confirm_password.errors.mustMatch">Passwords must match</div>
          </div>
       
      

        <div style="margin-top:2rem;" class= "row">
          <div class="col-sm-1"></div>
        <div class="col-md-2 col-sm-2">
        <div class="g-recaptcha" data-theme="light">
          <input type="text" class="form-control" formControlName="mycaptcha1" hidden required >
          <re-captcha (resolved)="resolved($event)" 
              formControlName="recaptchaReactive" 
              siteKey="6Ld9YPMZAAAAALZ7si32freu_SYFZe8UaZ7Z5NdN">  
          </re-captcha>
        </div>   
       </div>
       <div style="text-align: left;" *ngIf="step2 && f2.mycaptcha1.errors" class="invalid-feedback">
        <div *ngIf="f2.mycaptcha1.errors.required">Confirm captcha</div>
       </div> 
       </div>


      <div style="margin-top:1rem;" class= "row">
          <div class="col-lg-7"></div>
          <div class="col-lg-7" style="margin-left: 3rem;">
            <label class="custom-control custom-checkbox pull-left">
                <input (change)="listener($event)" type="checkbox" formControlName="agreeTerms" class="custom-control-input" />
                <span *ngIf="f2.agreeTerms.value == true" class="custom-control-label text-dark text-wrap">&emsp;Yes, I have read and agree with the  <a routerLink="/terms" target="_blank" style="color:#14baf1;">Terms & Conditions</a> & <a routerLink="/privacy-policy" target="_blank" style="color:#14baf1;">Privacy Policy</a></span>
                <span *ngIf="f2.agreeTerms.value == false" class="custom-control-label text-red text-wrap">&emsp;Yes, I have read and agree with the  <a routerLink="/terms" target="_blank" style="color:#14baf1;">Terms & Conditions</a> & <a routerLink="/privacy-policy" target="_blank" style="color:#14baf1;">Privacy Policy</a></span>                                  
            </label>
          </div>
          <br>
        </div>      


     <div style="margin-top:1rem;" class="row">
      <div class="example-button-row" style=" margin-left:1rem; margin-bottom:2rem">
          <button mat-raised-button class="previous"><a routerLink="/new-signup-form">Previous</a></button>
        </div>


      <div class="example-button-row" style="padding-left:10rem; margin-bottom:2rem;">
        <button mat-raised-button class="nextbtn ml-3" (click)="onSubmit()">Submit</button>
      </div>
      </div>
      
  </form>
</mat-card-content>
</mat-card>
</div>
<div class="col-md-4"></div>
</div>
</div>
</div>

<login-home-footer></login-home-footer>
  
  
