import { Component } from '@angular/core';

@Component({
  selector: 'app-prasannaengineers',
  templateUrl: './prasannaengineers.component.html',
  styleUrls: ['./prasannaengineers.component.scss']
})
export class PrasannaengineersComponent {

}
