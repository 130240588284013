import { Component } from '@angular/core';

@Component({
  selector: 'app-penta-metal-solutions',
  templateUrl: './penta-metal-solutions.component.html',
  styleUrls: ['./penta-metal-solutions.component.scss']
})
export class PentaMetalSolutionsComponent {

}
