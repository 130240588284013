import { Component } from '@angular/core';

@Component({
  selector: 'app-rawgold-metal',
  templateUrl: './rawgold-metal.component.html',
  styleUrls: ['./rawgold-metal.component.scss']
})
export class RawgoldMetalComponent {

}
