import { Component } from '@angular/core';

@Component({
  selector: 'app-sai-enterprises',
  templateUrl: './sai-enterprises.component.html',
  styleUrls: ['./sai-enterprises.component.scss']
})
export class SaiEnterprisesComponent {

}
