import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-bearing-co-pvt-ltd',
  templateUrl: './contact-bearing-co-pvt-ltd.component.html',
  styleUrls: ['./contact-bearing-co-pvt-ltd.component.scss']
})
export class ContactBearingCoPvtLtdComponent {

}
