import { Component } from '@angular/core';

@Component({
  selector: 'app-aditya-autos',
  templateUrl: './aditya-autos.component.html',
  styleUrls: ['./aditya-autos.component.scss']
})
export class AdityaAutosComponent {

}
