import { Component } from '@angular/core';

@Component({
  selector: 'app-reflect-additive',
  templateUrl: './reflect-additive.component.html',
  styleUrls: ['./reflect-additive.component.scss']
})
export class ReflectAdditiveComponent {

}
