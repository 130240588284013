import { Component } from '@angular/core';

@Component({
  selector: 'app-tssms-india-pvt-ltd',
  templateUrl: './tssms-india-pvt-ltd.component.html',
  styleUrls: ['./tssms-india-pvt-ltd.component.scss']
})
export class TssmsIndiaPvtLtdComponent {

}
