import { Component } from '@angular/core';

@Component({
  selector: 'app-microtech-precision',
  templateUrl: './microtech-precision.component.html',
  styleUrls: ['./microtech-precision.component.scss']
})
export class MicrotechPrecisionComponent {

}
