import { Component } from '@angular/core';

@Component({
  selector: 'app-parag-metals',
  templateUrl: './parag-metals.component.html',
  styleUrls: ['./parag-metals.component.scss']
})
export class ParagMetalsComponent {

}
