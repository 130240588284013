import { Component } from '@angular/core';

@Component({
  selector: 'app-shivanand-pressing-components',
  templateUrl: './shivanand-pressing-components.component.html',
  styleUrls: ['./shivanand-pressing-components.component.scss']
})
export class ShivanandPressingComponentsComponent {

}
