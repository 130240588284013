import { Component } from '@angular/core';

@Component({
  selector: 'app-beejay-industries-pvt-ltd',
  templateUrl: './beejay-industries-pvt-ltd.component.html',
  styleUrls: ['./beejay-industries-pvt-ltd.component.scss']
})
export class BeejayIndustriesPvtLtdComponent {

}
