import { Component } from '@angular/core';

@Component({
  selector: 'app-quality-engineering-co',
  templateUrl: './quality-engineering-co.component.html',
  styleUrls: ['./quality-engineering-co.component.scss']
})
export class QualityEngineeringCoComponent {

}
