import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../service/authentication.service';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../service/seo.service';
import { GlobalComponent } from '../global/global.component';


@Component({
  selector: 'login-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  name: any = "";
  email: any = "";
  subject: any = "";
  message: any = "";
  phone: any ="";
  hostName = GlobalComponent.hostName;
  
  constructor(public toastr: ToastrManager,
    private router: Router,
    private authenticationService: AuthenticationService,private SEOService:SeoService
    ) {
      //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
     }

  ngOnInit() {

    this.SEOService.updateCanonicalUrl(this.hostName + '/contactus');
    window.scrollTo(0, 0);
    
  }


  sendmessage(){
   
    // console.log("Subject-" + this.subject);
    // console.log("NAME-" + this.name);
    // console.log("Email" + this.email);
    // console.log("Phone" + this.phone);
    // console.log("Message" + this.message);

     if((this.name=="")||(this.subject=="")||(this.email=="")||(this.phone=="")||(this.message=="")) {

        this.toastr.errorToastr("All fields are required!");
        return false;

     }
     
    Swal.fire('Thank you for contacting us , Our Team will get back to you as soon as possible','','success');
   //console.log(this.phone);
    this.authenticationService.sendmessage(this.name, this.email, this.subject, this.message, this.phone)
    .pipe(first())
    .subscribe(
    data => {
      //console.log(data.message);
    //  this.toastr.successToastr('Thank you for your contacting us. Our team will respond you within 24hrs ', 'Success!');
    });
  }

}
