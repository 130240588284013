import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/service/authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-bidding-signup',
  templateUrl: './bidding-signup.component.html',
  styleUrls: ['./bidding-signup.component.scss']
})
export class BiddingSignupComponent implements OnInit {

  profile:any;
  next:boolean=false;
  firstFormGroup: FormGroup;
  fileUploaded: boolean = false;
  filename: string = "";
  pro_doc: any;
  show: boolean = false;
  show1: boolean = false;

  disable: boolean = false;
  userid: any;
  exists: boolean;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    public toastr: ToastrManager,
    private authenticationService: AuthenticationService,) { }

  ngOnInit(): void {
    this.firstFormGroup = this.formBuilder.group({
      profile: ['yes', Validators.required],
      company_name: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9@#%&*$^][ A-Za-z0-9.@#%&*$^]+$')]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.pattern("[0-9][0-9]{9}")]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$')]],
    });
  }

  get f() {    
    return this.firstFormGroup.controls;
  }


onFileChange(event) {
  this.pro_doc = event.target.files[0];
    let file: File = event.target.files[0]; 
    this.filename = file.name;

    if (file.size > 3 * 1024 * 1024) { // 3MB limit
      this.toastr.errorToastr("File size exceeds 3MB limit.", 'Error!');
      this.filename = ""; // Reset filename
      return;
    }

    const allowedExtensions = /(\.pdf)$/i; // Only allow .pdf files
    if (!allowedExtensions.exec(this.filename)) {
    this.toastr.errorToastr("Only PDF files are allowed.", 'Error!');
    this.filename = ""; // Reset filename
    return;
  }
}

checkradio(e){    
    
  this.profile = e.target.value;
  
}

onSubmit() { 

     this.next = true;

      if (this.f.profile.value == 'yes' && this.filename == '') {
        this.toastr.errorToastr("Please Upload File", 'Error!');
        return;
     }
      if (this.firstFormGroup.invalid) {
          return;
      }

  // console.log(this.f.company_name.value);
  // console.log(this.f.email.value);
  // console.log(this.f.phone.value);
  // console.log(this.pro_doc);
  

      this.disable = true;
      const formData = new FormData();      

      formData.append('company_name', this.f.company_name.value);
      formData.append('email',this.f.email.value);
      formData.append('contact_no',this.f.phone.value);
      formData.append('profile',this.pro_doc);    

    this.authenticationService.bid_signup(formData)
    .pipe(first())
    .subscribe(
        data => {   
         // console.log(data.message);
          

          if(data.message){
            this.exists = true;
            Swal.fire('This email id already exists', '', 'warning');
            // this.router.navigate((['/']));

          }
          else{
            this.toastr.successToastr('Signed Up Successfully! Please Verify Email Account!', 'Success!');
            this.router.navigate((['/']));
          }

        },
        error => {
          this.toastr.errorToastr("Signup Network Error", 'Error!');
        });

}

}
