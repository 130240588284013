import { Component } from '@angular/core';

@Component({
  selector: 'app-vartis-enigneering-pvt-ltd',
  templateUrl: './vartis-enigneering-pvt-ltd.component.html',
  styleUrls: ['./vartis-enigneering-pvt-ltd.component.scss']
})
export class VartisEnigneeringPvtLtdComponent {

}
