import { Component } from '@angular/core';

@Component({
  selector: 'app-aaditya-industries',
  templateUrl: './aaditya-industries.component.html',
  styleUrls: ['./aaditya-industries.component.scss']
})
export class AadityaIndustriesComponent {

}
