import { Component } from '@angular/core';

@Component({
  selector: 'app-auto-international',
  templateUrl: './auto-international.component.html',
  styleUrls: ['./auto-international.component.scss']
})
export class AutoInternationalComponent {

}
