import { Component } from '@angular/core';

@Component({
  selector: 'app-horizon-steels-and-engineering',
  templateUrl: './horizon-steels-and-engineering.component.html',
  styleUrls: ['./horizon-steels-and-engineering.component.scss']
})
export class HorizonSteelsAndEngineeringComponent {

}
