import { Component } from '@angular/core';

@Component({
  selector: 'app-rohit-group-of-industries',
  templateUrl: './rohit-group-of-industries.component.html',
  styleUrls: ['./rohit-group-of-industries.component.scss']
})
export class RohitGroupOfIndustriesComponent {

}
