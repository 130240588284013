import { Component } from '@angular/core';

@Component({
  selector: 'app-multi-technology',
  templateUrl: './multi-technology.component.html',
  styleUrls: ['./multi-technology.component.scss']
})
export class MultiTechnologyComponent {

}
