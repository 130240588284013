import { Component } from '@angular/core';

@Component({
  selector: 'app-om-kartik',
  templateUrl: './om-kartik.component.html',
  styleUrls: ['./om-kartik.component.scss']
})
export class OmKartikComponent {

}
