import { Component } from '@angular/core';

@Component({
  selector: 'app-fix-yantra-engineering',
  templateUrl: './fix-yantra-engineering.component.html',
  styleUrls: ['./fix-yantra-engineering.component.scss']
})
export class FixYantraEngineeringComponent {

}
