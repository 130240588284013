import { Component } from '@angular/core';

@Component({
  selector: 'app-pi-industries',
  templateUrl: './pi-industries.component.html',
  styleUrls: ['./pi-industries.component.scss']
})
export class PiIndustriesComponent {

}
