import { Component } from '@angular/core';

@Component({
  selector: 'app-jyothi-engineering-works',
  templateUrl: './jyothi-engineering-works.component.html',
  styleUrls: ['./jyothi-engineering-works.component.scss']
})
export class JyothiEngineeringWorksComponent {

}
