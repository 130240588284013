import { Component } from '@angular/core';

@Component({
  selector: 'app-krupa-engineering',
  templateUrl: './krupa-engineering.component.html',
  styleUrls: ['./krupa-engineering.component.scss']
})
export class KrupaEngineeringComponent {

}
