<!--Loader-->
<!-- <div id="global-loader">
  <img src="assets/images/loader.svg" class="loader-img" alt="">
</div> -->

<router-outlet></router-outlet>

<!-- Back to top -->
<!-- <a href="#top" id="back-to-top"><i class="fa fa-long-arrow-up"></i></a>
<div id="mybutton">
  <button class="feedback">Request Demo</button> 
  <a routerLink="/contactus" class="btn btn-info" role="button">Request Demo</a> -->
<!-- </div> --> 

