import { Component } from '@angular/core';

@Component({
  selector: 'app-har-engineering-traders',
  templateUrl: './har-engineering-traders.component.html',
  styleUrls: ['./har-engineering-traders.component.scss']
})
export class HarEngineeringTradersComponent {

}
