import { Component } from '@angular/core';

@Component({
  selector: 'app-ramji-springs',
  templateUrl: './ramji-springs.component.html',
  styleUrls: ['./ramji-springs.component.scss']
})
export class RamjiSpringsComponent {

}
