import { Component } from '@angular/core';

@Component({
  selector: 'app-durable-metalcraft',
  templateUrl: './durable-metalcraft.component.html',
  styleUrls: ['./durable-metalcraft.component.scss']
})
export class DurableMetalcraftComponent {

}
