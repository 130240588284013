import { Component } from '@angular/core';

@Component({
  selector: 'app-dattaguru-industries-pvt-ltd',
  templateUrl: './dattaguru-industries-pvt-ltd.component.html',
  styleUrls: ['./dattaguru-industries-pvt-ltd.component.scss']
})
export class DattaguruIndustriesPvtLtdComponent {

}
