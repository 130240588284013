import { Component } from '@angular/core';

@Component({
  selector: 'app-poona-pressings-pvt-ltd',
  templateUrl: './poona-pressings-pvt-ltd.component.html',
  styleUrls: ['./poona-pressings-pvt-ltd.component.scss']
})
export class PoonaPressingsPvtLtdComponent {

}
