import { Component } from '@angular/core';

@Component({
  selector: 'app-teron-metal-components',
  templateUrl: './teron-metal-components.component.html',
  styleUrls: ['./teron-metal-components.component.scss']
})
export class TeronMetalComponentsComponent {

}
