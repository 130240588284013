import { Component } from '@angular/core';

@Component({
  selector: 'app-khk-industries',
  templateUrl: './khk-industries.component.html',
  styleUrls: ['./khk-industries.component.scss']
})
export class KhkIndustriesComponent {

}
