import { Injectable } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { User } from 'src/app/model/user.model';
import { first, map, take } from 'rxjs/operators';
//import { pipe } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
  })

export class ActWarningService {

    currentUser: User;
    warning: any;
    previousUrl: string;

    constructor(
        private authenticationService: AuthenticationService,
        private router: Router,) { 
          this.authenticationService.currentUser.subscribe(x => this.currentUser = x); 
                     
        }
    
    warn_view_byr(vendorid, buyerid) {      
       return this.authenticationService.warn_view_byr(vendorid, buyerid)
         .pipe(take(1),
        map(data =>{ return data.message;}          
        ))    
    }

    warn_view_dwg(vendorid, rfqid) {      
        return this.authenticationService.warn_view_dwg(vendorid, rfqid)
          .pipe(take(1),
         map(data =>{ return data.message;}          
         ))    
     }

    view_rfq_details(userid, rfqid, rfq_form_id, keyword, category,
        searchkeyword, total, page, country, state, city){
        let anydata:any = this.currentUser;

        let balance_points:any;
        balance_points = JSON.parse(localStorage.getItem('balance_points'));
       // balance_points = 0;
        if(balance_points == 0 && anydata.success.user.vendorpackage == 0){    
            Swal.fire({
                title: 'Alert!',
                text: 'You have exhausted your reward points!',
                icon: 'warning',
                confirmButtonText: 'Ok, Contact Sales!',            
            }).then((result) => {
                if (result.value) {                
                    this.router.navigate(['/dashboard','contact_sales',rfqid]);                  
                }        
            });          
        }else{
           
            if(country){
                this.router.navigate(['/dashboard','view_rfq', rfqid, rfq_form_id, searchkeyword, total, page, country, state, city], { queryParams: {searchkeyword: keyword, cat: category}});  
            }else{
                this.router.navigate(['/dashboard','view_rfq', rfqid, rfq_form_id], { queryParams: {searchkeyword: keyword, cat: category}});  
            }
        }
    
    }  
    
    view_byr_details(rfqid, userid, buyerid, form_id, keyword){
        
        let anydata:any = this.currentUser;
        let balance_points:any;
        balance_points = JSON.parse(localStorage.getItem('balance_points'));
       // balance_points = 0;
        if(balance_points == 0 && anydata.success.user.vendorpackage == 0){    
            Swal.fire({
                title: 'Alert!',
                text: 'You have exhausted your reward points!',
                icon: 'warning',
                confirmButtonText: 'Ok, Contact Sales!',            
            }).then((result) => {
                if (result.value) {                
                    this.router.navigate(['/dashboard','contact_sales',rfqid]);                  
                }        
            });          
        }else{
            // this.authenticationService.warn_view_byr(userid, buyerid)
            //  .pipe(first()) 
            //  .subscribe(
            //     data => {
            //     this.warning = data.message;
            //     console.log(this.warning);
                
            //     if(this.warning){
            //         Swal.fire({
            //             title: 'View Buyer Details?',
            //             text: 'Reward Points will be deducted!',
            //             icon: 'warning',
            //             showCancelButton: true,
            //             confirmButtonText: 'Yes, Show Details!',
            //             cancelButtonText: 'No, cancel it'
            //             }).then((result) => {
            //             if (result.value) {
            //                 if (result.value) {          
            //                     this.router.navigate(['/dashboard','show_buyer_profile', rfqid, form_id, buyerid ], { queryParams: {searchkeyword: keyword} });
            //                   }
            //             }      
            //             });
            //     }else{
            //         this.router.navigate(['/dashboard','show_buyer_profile', rfqid, form_id, buyerid ], { queryParams: {searchkeyword: keyword} });
            //     }
            // })

            this.router.navigate(['/dashboard','show_buyer_profile', rfqid, form_id, buyerid ], { queryParams: {searchkeyword: keyword} });

        }
    
    }
  }