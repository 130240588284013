import { Component } from '@angular/core';

@Component({
  selector: 'app-sap-engineers',
  templateUrl: './sap-engineers.component.html',
  styleUrls: ['./sap-engineers.component.scss']
})
export class SapEngineersComponent {

}
