import { Component } from '@angular/core';

@Component({
  selector: 'app-patel-brass-turnomatics-pvt-ltd',
  templateUrl: './patel-brass-turnomatics-pvt-ltd.component.html',
  styleUrls: ['./patel-brass-turnomatics-pvt-ltd.component.scss']
})
export class PatelBrassTurnomaticsPvtLtdComponent {

}
