import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-siddharthengineering',
  templateUrl: './siddharthengineering.component.html',
  styleUrls: ['./siddharthengineering.component.scss']
})
export class SiddharthengineeringComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
