import { Component } from '@angular/core';

@Component({
  selector: 'app-metalls-international',
  templateUrl: './metalls-international.component.html',
  styleUrls: ['./metalls-international.component.scss']
})
export class MetallsInternationalComponent {

}
