import { Component } from '@angular/core';

@Component({
  selector: 'app-sarvoday-engineering',
  templateUrl: './sarvoday-engineering.component.html',
  styleUrls: ['./sarvoday-engineering.component.scss']
})
export class SarvodayEngineeringComponent {

}
