import { Component } from '@angular/core';

@Component({
  selector: 'app-maxwell-auto-components-pvt-ltd',
  templateUrl: './maxwell-auto-components-pvt-ltd.component.html',
  styleUrls: ['./maxwell-auto-components-pvt-ltd.component.scss']
})
export class MaxwellAutoComponentsPvtLtdComponent {

}
