import { Component } from '@angular/core';

@Component({
  selector: 'app-solaria-technology-pvt-ltd',
  templateUrl: './solaria-technology-pvt-ltd.component.html',
  styleUrls: ['./solaria-technology-pvt-ltd.component.scss']
})
export class SolariaTechnologyPvtLtdComponent {

}
