import { Component } from '@angular/core';

@Component({
  selector: 'app-oviyan-cast-n-forge-pvt-ltd',
  templateUrl: './oviyan-cast-n-forge-pvt-ltd.component.html',
  styleUrls: ['./oviyan-cast-n-forge-pvt-ltd.component.scss']
})
export class OviyanCastNForgePvtLtdComponent {

}
