import { Component } from '@angular/core';

@Component({
  selector: 'app-asian-machine-tool-corporation-pvt-ltd',
  templateUrl: './asian-machine-tool-corporation-pvt-ltd.component.html',
  styleUrls: ['./asian-machine-tool-corporation-pvt-ltd.component.scss']
})
export class AsianMachineToolCorporationPvtLtdComponent {

}
