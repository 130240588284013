<div class="banner-1 cover-image bg-background1" data-image-src="assets/images/banners/banner4.jpg">
    <login-home-navigation></login-home-navigation>


</div>
	<!--Section-->
    <section class="innerpage-margin sptb " style="background-color: #fff;">
        <div class="container">
        <h2 style="margin-bottom:2%"> Frequently Asked Questions?</h2>
            <div aria-multiselectable="true" class="accordion1" id="accordion" role="tablist">
                 <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse1">
                        1. Who qualifies to become a vendor or supplier?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse1" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;" >
                            Companies that engage in custom-manufacturing activities and are registered under the government-authorized industry laws of their respective countries are eligible to become vendors or suppliers.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse2">
                        2. Who qualifies to become a buyer?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse2" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Any company interested in outsourcing manufacturing activities may become a buyer.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse3">
                        3. What are the charges associated with this platform?
                    </div>
                    <div  class="collapse" data-parent="#accordion" id="collapse3" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            The subscription packages for this platform start at approximately 60 INR per day. For more information on the different packages available, checkout <a href="./newpricingpage"target="_blank" >Vendor Packages</a>.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse4">
                        4.  What assurance does the platform offer for business success?
                    </div>
                    <div  class="collapse" data-parent="#accordion" id="collapse4" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our short-term subscriber conversion rate is around 50%, while our long-term subscriber conversion rate is an impressive 99%. Additionally, our tailored packages come with a money-back guarantee.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse5">
                        5. How often do new businesses (RFQ) appear on the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse5" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our database is updated with fresh business opportunities every Tuesday and Friday of each week.
                        </div>
                    </div>
                </div>
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse6">
                        6. How many registrations are allowed under a single company name?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse6" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our Gold package allows for multiple registrations under one company name. Users can be added to a package by paying nominal user fees.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse7">
                       7. How do I contact the buyer for further communication once a new RFQ is found?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse7" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our technology-driven platform assists you in securing orders once you identify a suitable RFQ.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse8">
                        8. Is my data secure on the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse8" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Your data, including drawings, quotations, and profile information, is safeguarded by our advanced security measures and digital non-disclosure agreement features.
                        </div>
                    </div>
                </div>
                
                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse9">
                        9. What types of buyers are using the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse9" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Our buyers come from a variety of sectors including Automotive, Engineering, Aerospace, Shipbuilding, Machine Manufacturing, Oil and Gas, Construction, Railways, Chemicals, Electronics, Electrical, 3D Printing, and Pharmaceutical industries.
                        </div>
                    </div>
                </div>

                <div class="card mb-3">
                    <div class="accordion-header" data-toggle="collapse" data-target="#collapse10">
                        10. How can I register my company on the platform?
                    </div>
                    <div class="collapse" data-parent="#accordion" id="collapse10" role="tabpanel">
                        <div class="accordion-body" style="background-color:white; font-weight: 600;">
                            Click on the <a href="/login/signup"> link </a> to initiate a lasting partnership with GetYourVendor.
                        </div>
                    </div>
                </div>
                
            </div><!-- accordion -->
        </div>
    </section>
    <!--/Section-->
  
<!-- /Conatct -->


<login-home-footer></login-home-footer>
