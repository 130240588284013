import { Component } from '@angular/core';

@Component({
  selector: 'app-tool-tech',
  templateUrl: './tool-tech.component.html',
  styleUrls: ['./tool-tech.component.scss']
})
export class ToolTechComponent {

}
