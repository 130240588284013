import { Component } from '@angular/core';

@Component({
  selector: 'app-sagar-industries',
  templateUrl: './sagar-industries.component.html',
  styleUrls: ['./sagar-industries.component.scss']
})
export class SagarIndustriesComponent {

}
