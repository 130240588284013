export class DynamicScriptsService {
    
    loadrazorScript() {      
        const scriptElement = window.document.createElement('script');
        scriptElement.src = 'https://checkout.razorpay.com/v1/checkout.js';    
        window.document.body.appendChild(scriptElement);      
    }

    loadrecaptchScript() {      
        const scriptElement = window.document.createElement('script');
        scriptElement.src = 'https://www.google.com/recaptcha/api.js';    
        window.document.body.appendChild(scriptElement);      
    }
  }