import { Component } from '@angular/core';

@Component({
  selector: 'app-viraj-technocast-pvt-ltd',
  templateUrl: './viraj-technocast-pvt-ltd.component.html',
  styleUrls: ['./viraj-technocast-pvt-ltd.component.scss']
})
export class VirajTechnocastPvtLtdComponent {

}
