import { Component } from '@angular/core';

@Component({
  selector: 'app-gemba-metal-forming-pvt-ltd',
  templateUrl: './gemba-metal-forming-pvt-ltd.component.html',
  styleUrls: ['./gemba-metal-forming-pvt-ltd.component.scss']
})
export class GembaMetalFormingPvtLtdComponent {

}
