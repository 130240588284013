import { Component } from '@angular/core';

@Component({
  selector: 'app-deccan-industries',
  templateUrl: './deccan-industries.component.html',
  styleUrls: ['./deccan-industries.component.scss']
})
export class DeccanIndustriesComponent {

}
