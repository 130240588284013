import { Component, OnInit } from '@angular/core';
import { GlobalComponent } from '../global/global.component';
import { SeoService } from '../service/seo.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }

  ngOnInit(){

  this.SEOService.updateCanonicalUrl(this.hostName + '/privacy');
  window.scroll(0,0);

}
}
