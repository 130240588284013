import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AlertService } from '../service/alert.service';
import { AuthenticationService } from '../service/authentication.service';
import { SeoService } from '../service/seo.service';
import { GlobalComponent } from '../global/global.component';


@Component({
  selector: 'app-forgotpass',
  templateUrl: './forgotpass.component.html',
  styleUrls: ['./forgotpass.component.scss']
})
export class ForgotpassComponent implements OnInit {

  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  SEOService: any;
  hostName = GlobalComponent.hostName;
  constructor(
      private formBuilder: UntypedFormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private alertService: AlertService
  ) {
      // redirect to home if already logged in
      if (this.authenticationService.currentUserValue) { 
          this.router.navigate(['/dashboard', 'dashboard']);
      }
      this.loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    });
  }


   keyword = 'name';
  data = [
     {
       id: 1,
       name: 'Usa'
     },
     {
       id: 2,
       name: 'England'
     }
  ];
 
 
  selectEvent(item) {
    // do something with selected item
  }
 
  resolved(captchaResponse: string) {
    //console.log(`Resolved response token: ${captchaResponse}`);
   
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }
  
  onFocused(e){
    // do something when input is focused
  }
  

  ngOnInit() {

    this.SEOService.updateCanonicalUrl(this.hostName + '/forgotpassword'); 
      // this.loginForm = this.formBuilder.group({
      //     email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      // });

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
          return;
      }

      this.loading = true;
      //console.log(this.f.email.value);
      this.authenticationService.forgotpass(this.f.email.value)
          .pipe(first())
          .subscribe(
              data => {
               
              // console.log(data.message);
             
                  Swal.fire('Reset instructions sent to your registered email , do check spam folder in case not received !!', '', 'success');
                  this.router.navigate(['/login']);
             
                this.loading = false;


                // if(data.message){
                //   this.router.navigate(['/login']);
                // }else{
                //   this.router.navigate(['/dashboard', 'dashboard']);
                // }
                  
              },
              error => {
                  this.alertService.error(error);
                  this.loading = false;
              });
  }

}
