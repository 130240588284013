import { Component } from '@angular/core';

@Component({
  selector: 'app-puja-fluid-seals-pvt-ltd',
  templateUrl: './puja-fluid-seals-pvt-ltd.component.html',
  styleUrls: ['./puja-fluid-seals-pvt-ltd.component.scss']
})
export class PujaFluidSealsPvtLtdComponent {

}
