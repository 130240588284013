import { Component } from '@angular/core';

@Component({
  selector: 'app-scanda-industries',
  templateUrl: './scanda-industries.component.html',
  styleUrls: ['./scanda-industries.component.scss']
})
export class ScandaIndustriesComponent {

}
