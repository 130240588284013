import { Component } from '@angular/core';

@Component({
  selector: 'app-sk-technologys',
  templateUrl: './sk-technologys.component.html',
  styleUrls: ['./sk-technologys.component.scss']
})
export class SkTechnologiesComponent {

}
