import { Component } from '@angular/core';

@Component({
  selector: 'app-khavare-engineering',
  templateUrl: './khavare-engineering.component.html',
  styleUrls: ['./khavare-engineering.component.scss']
})
export class KhavareEngineeringComponent {

}
