import { Component } from '@angular/core';

@Component({
  selector: 'app-saienterprises',
  templateUrl: './saienterprises.component.html',
  styleUrls: ['./saienterprises.component.scss']
})
export class SaienterprisesComponent {

}
