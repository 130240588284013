import { Component } from '@angular/core';

@Component({
  selector: 'app-shri-datta-industries',
  templateUrl: './shri-datta-industries.component.html',
  styleUrls: ['./shri-datta-industries.component.scss']
})
export class ShriDattaIndustriesComponent {

}
