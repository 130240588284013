import { Component } from '@angular/core';

@Component({
  selector: 'app-hexagon-indurstries',
  templateUrl: './hexagon-indurstries.component.html',
  styleUrls: ['./hexagon-indurstries.component.scss']
})
export class HexagonIndurstriesComponent {

}
