import { Component } from '@angular/core';

@Component({
  selector: 'app-nikash-cnc-engineers-pvt-ltd',
  templateUrl: './nikash-cnc-engineers-pvt-ltd.component.html',
  styleUrls: ['./nikash-cnc-engineers-pvt-ltd.component.scss']
})
export class NikashCncEngineersPvtLtdComponent {

}
