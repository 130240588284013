
<div class="banner-1"   >
    <!-- <login-home-navigation></login-home-navigation> -->

    <p>&nbsp;</p>
</div>

<section class="sptb" style="margin-top: 50px; background-color: white;">
    <div class="container" >
        <div class="row">
            <div class="col-md-3 col-sm-3">
                
                    <div style="display: flex; justify-content: center;">
                        <img src="assets/images/media/GYV_vendor.webp">
                    </div>
                

            </div>
            <div class="col-md-9 col-sm-9">
                
                    <p style="text-transform: uppercase; font-size: 36px;">PARTH MANUFACTURING PVT.LTD.</p>
                    <p><i class="fa fa-map-marker" aria-hidden="true"></i>Gala no 11 madhu vrinda industrial estate phase no 3 Dhuri Industrial complex no 1 waliv phata village waliv  vasai East   Palgahr  401208 Maharastra India</p>
                    <p>GST: 27AALCP9960G1ZT </p>
                    <p><span style="color: red; font-style: italic; font-size: 12px;">Verified Vendor</span></p>
                </div>
        </div>
    </div>
</section>
<section class="sptb" style="background-color: white;">
    <div class="container">
        <div class="card" style="padding: 10px;">
            <p class="card-title" style="display: flex;justify-content: center;">About Company</p>
            <p >PARTH MANUFACTURING PVT.LTD. is one of the best custom manufacturing company established in 1998. They are experts in Machining Process, Precision Machining, 
                Grinding, Dies and Tools, EDM and Wire Cut, Water Jet Cutting, Gear Making, Sub Assemblies and Testing, Panel Fabrication, Sheet Metal Fabrication , 
                Stainless Steel Fabrication, Heavy Fabrication, Tube Fabrication.</p>
        </div>
        
    </div>
</section>

<section class="sptb" style="background-color: white;">
    <div class="container">
        <!-- <div class="card" style="padding: 15%;"> -->
            <div class="row">
                <div class="col-md-3 col-sm-3 vertical-center " style="border-right:2px solid black;">
                    <p><i class="fa fa-briefcase fa-2x" aria-hidden="true"></i> Nature of Business: </p> <p><b> Manufacturer</b></p>
                    <p><i class="fa fa-users fa-2x" aria-hidden="true"></i> Total Number of Employees: </p> <p><b>46</b></p>
                    <p><i class="fa fa-money fa-2x" aria-hidden="true"></i> Yearly Turnover: </p> <p><b>20-40</b></p>
                </div>
                <div class="col-md-3 col-sm-3 vertical-center" style="border-right:2px solid black;">
                    <p><i class="fa fa-industry fa-2x" aria-hidden="true"></i> Total Area: </p> <p><b>5000 Sq.Ft</b></p>
                    <p><i class="fa fa-calendar fa-2x" aria-hidden="true"></i> Establishment Year: </p> <p><b>2021</b></p>
                    <p><i class="fa fa-industry fa-2x" aria-hidden="true"></i> Factory Premises: </p> <p><b>Rented</b></p>
                </div>
                <div class="col-md-3 col-sm-3 vertical-center" style="border-right:2px solid black;">
                    <p><i class="fa fa-line-chart fa-2x" aria-hidden="true"></i> Domestic Sale: </p> <p><b>-</b></p>
                    <p><i class="fa fa-line-chart fa-2x" aria-hidden="true"></i> Export Sales: </p> <p><b>-</b></p>
                    <p><i class="fa fa-plug fa-2x" aria-hidden="true"></i> Sanctioned Power: </p> <p><b>65Kw</b></p>
                </div>
                <div class="col-md-3 col-sm-3 vertical-center" style="border-right:2px solid black;">
                    <p><i class="fa fa-university fa-2x" aria-hidden="true"></i>Banker:</p><p><b> -      </b></p>
                    
                </div>
            </div>
        <!-- </div> -->

    </div>
</section>

    








