import { Component } from '@angular/core';

@Component({
  selector: 'app-ganesh-industries',
  templateUrl: './ganesh-industries.component.html',
  styleUrls: ['./ganesh-industries.component.scss']
})
export class GaneshIndustriesComponent {

}
