import { Component } from '@angular/core';

@Component({
  selector: 'app-sasa-enggtech-pvt-ltd',
  templateUrl: './sasa-enggtech-pvt-ltd.component.html',
  styleUrls: ['./sasa-enggtech-pvt-ltd.component.scss']
})
export class SasaEnggtechPvtLtdComponent {

}
