import { Component } from '@angular/core';

@Component({
  selector: 'app-gunjals-industries',
  templateUrl: './gunjals-industries.component.html',
  styleUrls: ['./gunjals-industries.component.scss']
})
export class GunjalsIndustriesComponent {

}
