import { Component } from '@angular/core';

@Component({
  selector: 'app-kepio-engineering-services-pvt-ltd',
  templateUrl: './kepio-engineering-services-pvt-ltd.component.html',
  styleUrls: ['./kepio-engineering-services-pvt-ltd.component.scss']
})
export class KepioEngineeringServicesPvtLtdComponent {

}
