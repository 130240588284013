import { Component } from '@angular/core';

@Component({
  selector: 'app-efficnc-engineering-llp',
  templateUrl: './efficnc-engineering-llp.component.html',
  styleUrls: ['./efficnc-engineering-llp.component.scss']
})
export class EfficncEngineeringLlpComponent {

}
