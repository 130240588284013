import { Component } from '@angular/core';

@Component({
  selector: 'app-precimetal-works',
  templateUrl: './precimetal-works.component.html',
  styleUrls: ['./precimetal-works.component.scss']
})
export class PrecimetalWorksComponent {

}
