import { Component } from '@angular/core';

@Component({
  selector: 'app-range-precise',
  templateUrl: './range-precise.component.html',
  styleUrls: ['./range-precise.component.scss']
})
export class RangePreciseComponent {

}
