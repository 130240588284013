    <login-home-navigation></login-home-navigation>
  

    <section class="sptb" style="background-color: white;">
        <div class="container">

            <div class="row">
            
            <div class="col-lg-6 col-sm-4 col-md-6 d-block mx-auto">
                <div class="container" style="text-align: center;">
                    
                        <h1>Request Reset Password Link</h1>
                       
                    
                </div>
            </div>
   
    
      
            <div class="single-page">
                <div class="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
                    <div class="wrapper wrapper2">
                     
                        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="card-body" >
                            <div class="mail">
                                <input type="email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                <label>Enter Registered Email</label>
                                <div style="text-align: left;" *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Valid Email Id is required</div>
                                    <div *ngIf="f.email.errors.pattern">Enter Valid Email Id</div>
                                </div>
                            </div>
                          


                            <div class="submit">
                                <button [disabled]="loading" class="btn btn-info">
                                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                     Reset Password 
                                </button>
                            </div>
                            
                        </form>



                      
            
            
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<login-home-footer></login-home-footer>