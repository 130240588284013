import { Component, OnInit } from '@angular/core';
import { GlobalComponent } from '../global/global.component';
import { SeoService } from '../service/seo.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }

  ngOnInit(){

  this.SEOService.updateCanonicalUrl(this.hostName + '/terms');
  window.scroll(0,0);

  }
}
