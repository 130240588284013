import { Component } from '@angular/core';

@Component({
  selector: 'app-yash-engineering-works',
  templateUrl: './yash-engineering-works.component.html',
  styleUrls: ['./yash-engineering-works.component.scss']
})
export class YashEngineeringWorksComponent {

}
