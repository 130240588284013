import { Component } from '@angular/core';

@Component({
  selector: 'app-sharanyapolymer',
  templateUrl: './sharanyapolymer.component.html',
  styleUrls: ['./sharanyapolymer.component.scss']
})
export class SharanyapolymerComponent {

}
