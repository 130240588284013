import { Component, OnInit } from '@angular/core';


declare let $;
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  constructor() { 
  
  }

  ngOnInit(): void {
    // $('#horizontal-navtoggle').on("click", function(e) {
		// 	$('body').toggleClass('active');
		// });
  }

}
