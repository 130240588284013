import { Component } from '@angular/core';

@Component({
  selector: 'app-innovic-technology',
  templateUrl: './innovic-technology.component.html',
  styleUrls: ['./innovic-technology.component.scss']
})
export class InnovicTechnologyComponent {

}
