import { Component } from '@angular/core';

@Component({
  selector: 'app-kuber-enterprises',
  templateUrl: './kuber-enterprises.component.html',
  styleUrls: ['./kuber-enterprises.component.scss']
})
export class KuberEnterprisesComponent {

}
