import { Component } from '@angular/core';

@Component({
  selector: 'app-kalariya-industries',
  templateUrl: './kalariya-industries.component.html',
  styleUrls: ['./kalariya-industries.component.scss']
})
export class KalariyaIndustriesComponent {

}
