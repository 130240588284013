import { Component } from '@angular/core';

@Component({
  selector: 'app-shiv-spares',
  templateUrl: './shiv-spares.component.html',
  styleUrls: ['./shiv-spares.component.scss']
})
export class ShivSparesComponent {

}
