import { Component } from '@angular/core';

@Component({
  selector: 'app-shree-sai-industries',
  templateUrl: './shree-sai-industries.component.html',
  styleUrls: ['./shree-sai-industries.component.scss']
})
export class ShreeSaiIndustriesComponent {

}
