import { Component } from '@angular/core';

@Component({
  selector: 'app-aim-precision-industries-pvt-ltd',
  templateUrl: './aim-precision-industries-pvt-ltd.component.html',
  styleUrls: ['./aim-precision-industries-pvt-ltd.component.scss']
})
export class AimPrecisionIndustriesPvtLtdComponent {

}
