import { Component } from '@angular/core';

@Component({
  selector: 'app-innovative-industries',
  templateUrl: './innovative-industries.component.html',
  styleUrls: ['./innovative-industries.component.scss']
})
export class InnovativeIndustriesComponent {

}
