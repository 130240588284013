import { Component } from '@angular/core';

@Component({
  selector: 'app-kirdak-autocom-pvt-ltd',
  templateUrl: './kirdak-autocom-pvt-ltd.component.html',
  styleUrls: ['./kirdak-autocom-pvt-ltd.component.scss']
})
export class KirdakAutocomPvtLtdComponent {

}
