import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthenticationService } from '../service/authentication.service';
import { Meta, Title } from '@angular/platform-browser';
import { SeoService } from '../service/seo.service';
import { GlobalComponent } from '../global/global.component';
import { User } from '../model/user.model';


@Component({
  selector: 'login-public_rfq',
  templateUrl: './public_rfq.component.html',
  styleUrls: ['./public_rfq.component.scss']
})
export class PublicRFQComponent implements OnInit {
  
  hostName = GlobalComponent.hostName;
  rfqid: any;
  rfq: any;
  rfq_expired: string;
  previousUrl: string;
  currentUser: User;
  userid: any;
  
  constructor(public toastr: ToastrManager,
    private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,private SEOService:SeoService
    ) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
      //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      if(this.router.getCurrentNavigation().previousNavigation != null){
        this.previousUrl = this.router.getCurrentNavigation().previousNavigation.finalUrl.toString();
        localStorage.setItem('previousUrl', this.previousUrl);
        }else{
          this.previousUrl = localStorage.getItem('previousUrl');
        }
     }

  ngOnInit() {

   // this.SEOService.updateCanonicalUrl(this.hostName + '/rfq');

    this.route.paramMap.subscribe(params => {
      this.rfqid = params.get("no");
   
      // if(this.currentUser){
      //   let anydata: any;
      //   anydata = this.currentUser;
      //   this.userid = anydata.success.user.userid;
      //   this.authenticationService.rfqviewentry(this.userid, this.rfqid )
      //      .pipe(first())
      //      .subscribe(
      //          data => {
       
      //         // console.log(data.message);
      //      })
      // }

    this.authenticationService.get_public_rfq(this.rfqid)
              .pipe(first())
              .subscribe(
                  data => {        
                   // this.sel_cat = data.category.name;
                    
                    this.rfq = data.message;
                    this.rfq.instructions = this.rfq.instructions.replace(/(?<=(?:^|[.?!])\W*)[a-z]/g, i => i.toUpperCase());           
                    //console.log(this.rfq);
                   let subDate = this.rfq.submission_date;
                  let CurrentDate = new Date();
                  subDate = new Date(subDate);
                  if(subDate > CurrentDate){    
                    this.rfq_expired = "no";
                  }else{    
                    this.rfq_expired = "yes";
                  }

                  this.SEOService.updateCanonicalUrl(this.hostName + '/rfq/:no');
                  this.SEOService.updateTitle('RFQID' + '-' +  this.rfq.id );
                  },
                  error => {                
                     
                  });
    });
  }

  back(){
    if(this.previousUrl.includes('view_rfq')){  
      this.router.navigateByUrl('/dashboard/cat_rfqlist/' + this.rfq.category);
    //console.log("hi");
    }else{
      this.router.navigateByUrl(this.previousUrl);
    }
  }
}
