import { Component } from '@angular/core';

@Component({
  selector: 'app-engeetech-engineerings',
  templateUrl: './engeetech-engineerings.component.html',
  styleUrls: ['./engeetech-engineerings.component.scss']
})
export class EngeetechEngineeringsComponent {

}
