import { Component, OnInit } from '@angular/core';
import { GlobalComponent } from 'src/app/global/global.component';
import { SeoService } from 'src/app/service/seo.service';

@Component({
  selector: 'app-strategicpartner',
  templateUrl: './strategicpartner.component.html',
  styleUrls: ['./strategicpartner.component.scss']
})
export class StrategicpartnerComponent implements OnInit {

  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }

  ngOnInit(){

  this.SEOService.updateCanonicalUrl(this.hostName + '/strategicpartner');
  window.scrollTo(0, 0);

  }

}
