import { Component } from '@angular/core';

@Component({
  selector: 'app-delta-pi-engineering',
  templateUrl: './delta-pi-engineering.component.html',
  styleUrls: ['./delta-pi-engineering.component.scss']
})
export class DeltaPiEngineeringComponent {

}
