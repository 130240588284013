import { Component } from '@angular/core';

@Component({
  selector: 'app-shrushti-industries',
  templateUrl: './shrushti-industries.component.html',
  styleUrls: ['./shrushti-industries.component.scss']
})
export class ShrushtiIndustriesComponent {

}
