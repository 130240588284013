import { Component } from '@angular/core';

@Component({
  selector: 'app-rr-industries',
  templateUrl: './rr-industries.component.html',
  styleUrls: ['./rr-industries.component.scss']
})
export class RrIndustriesComponent {

}
