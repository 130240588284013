<div class="row">
    <div class="col-10"><h1 mat-dialog-title> Share GYV-RFQ{{data.id}}</h1></div>
    <div class="col-2">
    <button mat-dialog-close class="fe fe-x close_button pull-right" (click)="onCancelClick()" style="border: none;"></button>
    </div>
</div>
<div mat-dialog-content>
    <!-- <share-buttons theme="circles-dark"
    [include]="['copy', 'facebook', 'email', 'linkedin', 'sms', 'twitter', 'whatsapp', 'pinterest']"
    [showIcon]="true"
    [showText]="false"
    url={{data.link}}
    description="GYV RFQ {{data.link}}"
    twitterAccount="rodrigokamada"
    class="pt-5">
    </share-buttons> --> 
    <share-buttons theme="modern-dark"
      [include]="['copy', 'facebook', 'email', 'linkedin', 'pinterest', 'sms', 'twitter', 'whatsapp']"
      [showIcon]="true"
      [showText]="true"
      url={{data.link}}
      description="GYV RFQ {{data.id}}"
      twitterAccount="rodrigokamada"
      class="pt-5">
    </share-buttons>
</div>
<div mat-dialog-actions>  
    <!--<button mat-button [mat-dialog-close]="data.vendor_name" class="badge badge-success mr-2" cdkFocusInitial>Close RFQ</button>-->    
    <!-- <button mat-button class="badge badge-danger" (click)="onCancelClick()">Cancel</button> -->
  </div>