import { Component } from '@angular/core';

@Component({
  selector: 'app-praneel-industries',
  templateUrl: './praneel-industries.component.html',
  styleUrls: ['./praneel-industries.component.scss']
})
export class PraneelIndustriesComponent {

}
