import { Component } from '@angular/core';

@Component({
  selector: 'app-ds-foundry',
  templateUrl: './ds-foundry.component.html',
  styleUrls: ['./ds-foundry.component.scss']
})
export class DsFoundryComponent {

}
