import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/service/authentication.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-new-signup-form',
  templateUrl: './new-signup-form.component.html',
  styleUrls: ['./new-signup-form.component.scss']

})
export class NewSignupFormComponent implements OnInit{
  
  profile:any;
  next:boolean=false;
  firstFormGroup: FormGroup;
  fileUploaded: boolean = false;
  filename: string = "";
  pro_doc: any;
  secondFormGroup: FormGroup;
  agreeTerms: any;
  step2:boolean = false;
  show: boolean = false;
  show1: boolean = false;
  password = 'password';
  password1 = 'password';
  mycaptcha: boolean;
  show_step: string = 'one';
  disable: boolean = false;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    public toastr: ToastrManager,
    private authenticationService: AuthenticationService,
    ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.firstFormGroup = this.formBuilder.group({
      profile: ['no', Validators.required],	
      company_name: ['', [Validators.required, Validators.pattern('^[A-Za-z0-9@#%&*$^][ A-Za-z0-9.@#%&*$^]+$')]],
      contact_person: ['',[Validators.required,Validators.pattern('^[A-Za-z][ A-Za-z]+$')]],
      phone: ['', [Validators.required, Validators.pattern("[0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9][0-9]+$")]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]+$')]],
    });

    this.secondFormGroup = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', [Validators.required]],
      mycaptcha1: ['', [Validators.required, Validators.pattern("true")]],
      agreeTerms : [false, [Validators.requiredTrue]],
    }, {
      validators: this.mustMatch('password', 'confirm_password')
    });
  
  }

  // Function to validate password confirmation
  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }

      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  get f() {
    return this.firstFormGroup.controls;
  }
  
  get f2() {
    return this.secondFormGroup.controls;
  }
  
  firststep(){
      this.next = true;
      if (this.firstFormGroup.invalid) {
        this.toastr.errorToastr("Please enter all (*)compulsory fields", 'Error!');
        return;
      }
      if (this.f.profile.value == 'yes' && this.filename == '') {
        this.toastr.errorToastr("Please Upload File", 'Error!');
        return;
     }
      if (this.firstFormGroup.invalid) {
          return;
      }
      else{
        this.show_step = 'two';
        console.log(this.show_step);
      }
    
    
}

resolved(captchaResponse: string) {

 this.mycaptcha = true;
  this.secondFormGroup.patchValue({
    mycaptcha1: "true"
 });
   
}

onFileChange(event) {
  this.pro_doc = event.target.files[0];
    let file: File = event.target.files[0]; 
    this.filename = file.name;

    if (file.size > 3 * 1024 * 1024) { // 3MB limit
      this.toastr.errorToastr("File size exceeds 3MB limit.", 'Error!');
      this.filename = ""; // Reset filename
      return;
    }

    const allowedExtensions = /(\.pdf)$/i; // Only allow .pdf files
    if (!allowedExtensions.exec(this.filename)) {
    this.toastr.errorToastr("Only PDF files are allowed.", 'Error!');
    this.filename = ""; // Reset filename
    return;
  }
}

checkradio(e){    
    
  this.profile = e.target.value;
  //this.next = true;

}

listener(ev){
  // console.log(ev.currentTarget.checked);
   this.agreeTerms = ev.currentTarget.checked; 

 }

 
 onClick() {
  if (this.password === 'password') {
    this.password = 'text';
    this.show = true;
  } else {
    this.password = 'password';
    this.show = false;
  }
}

onClick1() {
//  console.log(this.password1);
  if (this.password1 === 'password') {
    this.password1 = 'text';
    this.show1 = true;
  } else {
    this.password1 = 'password';
    this.show1 = false;
  }
}

prev(){
  this.show_step = "one";
}

onSubmit() {  
  this.step2 = true;
    
  if(this.mycaptcha!=true){
    this.mycaptcha = false;
    return;
  }

  if(this.agreeTerms != true)
    {
      Swal.fire('To create an account, you must accept our terms of service and privacy policy.','','warning')
      return;
    }

  if (this.secondFormGroup.invalid) {
    return;
  }

  // console.log(this.f.company_name.value);
  // console.log(this.f.email.value);
  // console.log(this.f.phone.value);
  // console.log(this.f.contact_person.value);
  // console.log(this.f2.password.value);
  // console.log(this.pro_doc);
  this.disable = true;
  const formData = new FormData();      

      formData.append('company_name', this.f.company_name.value);
      formData.append('email',this.f.email.value);
      formData.append('contact_no',this.f.phone.value);
      formData.append('contact_person',this.f.contact_person.value);
      formData.append('password',this.f2.password.value);
      formData.append('profile',this.pro_doc);    

    this.authenticationService.add_lead(formData)
    .pipe(first())
    .subscribe(
        data => {   
         // console.log(data.message);
          //this.toastr.successToastr('Signed Up Successfully! Please Verify Email Account!', 'Success!');
          Swal.fire('Congratulations! Please check your inbox for the activation link. If you have not received it, please send an email to contact@getyourvendor.com.', '', 'success');
          this.router.navigate((['/']));
        },
        error => {
          this.toastr.errorToastr("Signup Network Error", 'Error!');
        });

}

}