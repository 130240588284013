import { Component } from '@angular/core';

@Component({
  selector: 'app-automatic-engineering-works',
  templateUrl: './automatic-engineering-works.component.html',
  styleUrls: ['./automatic-engineering-works.component.scss']
})
export class AutomaticEngineeringWorksComponent {

}
