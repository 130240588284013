import { Component } from '@angular/core';

@Component({
  selector: 'app-nirmala-engineering',
  templateUrl: './nirmala-engineering.component.html',
  styleUrls: ['./nirmala-engineering.component.scss']
})
export class NirmalaEngineeringComponent {

}
