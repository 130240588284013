import { Component } from '@angular/core';

@Component({
  selector: 'app-sadhu-forging-ltd',
  templateUrl: './sadhu-forging-ltd.component.html',
  styleUrls: ['./sadhu-forging-ltd.component.scss']
})
export class SadhuForgingLtdComponent {

}
