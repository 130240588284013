import { Component } from '@angular/core';

@Component({
  selector: 'app-dimension-enterprise',
  templateUrl: './dimension-enterprise.component.html',
  styleUrls: ['./dimension-enterprise.component.scss']
})
export class DimensionEnterpriseComponent {

}
