import { Component } from '@angular/core';

@Component({
  selector: 'app-swastik-cad-cam',
  templateUrl: './swastik-cad-cam.component.html',
  styleUrls: ['./swastik-cad-cam.component.scss']
})
export class SwastikCadCamComponent {

}
