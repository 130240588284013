import { Component, OnInit } from '@angular/core';
import { SeoService } from '../service/seo.service';
import { GlobalComponent } from '../global/global.component';

@Component({
  selector: 'login-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {
  hostName = GlobalComponent.hostName;

  constructor(private SEOService:SeoService) { }

  ngOnInit(){

  this.SEOService.updateCanonicalUrl(this.hostName + '/career');
  window.scrollTo(0, 0);

  }
  



    
  } 


