import { Component, Inject, Injectable } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ToastrManager } from 'ng6-toastr-notifications';
import { User } from 'src/app/model/user.model';
import { AuthenticationService } from 'src/app/service/authentication.service';

@Injectable({
  providedIn: 'root'
})

export class MsgDialogService {

  constructor(      
    public dialog: MatDialog,){}

  openMsgDialog(): void {
    this.dialog.open(DialogService,{
      width: '500px',
    });    
  }

  open_rfq_lmtDialog(): void {
    this.dialog.open(RFQLmtDialogService,{
      width: '500px',
    });    
  }

  open_adminDialog():void{
    this.dialog.open(admin_dialog,{width:'500px',});
  }

  openShareDialog(rfq_id, link): void {
    const dialogRef = this.dialog.open(DialogShareRFQViewDialog, {
      width: '500px',
      data: {link: link, id: rfq_id}
    });
  }
  
}

@Component({
  selector: 'service_dialog',
  templateUrl: './msgdialog.service.html',
 // standalone: true,
 // imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose],
})

export class DialogService {
    disable: boolean;
  
    constructor(      
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<DialogService>){}
  
    onCancelClick(): void {
      this.dialogRef.close();
    }


}

@Component({
  selector: 'rfq_lmt_dialog',
  templateUrl: './rfq_lmtdialog.service.html',
 // standalone: true,
 // imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose],
})
export class RFQLmtDialogService {
  disable: boolean;

  constructor(      
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RFQLmtDialogService>){}

  onCancelClick(): void {
    this.dialogRef.close();
  }
}

@Component({
    selector: 'admin_dialog',
    templateUrl: './admin_dialog.service.html',
   // standalone: true,
   // imports: [MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose],
  })
export class admin_dialog {
    disable: boolean;
  
    constructor(      
      public dialog: MatDialog,
      public dialogRef: MatDialogRef<admin_dialog>){}
  
    onCancelClick(): void {
      this.dialogRef.close();
    }
  

}

export interface DialogShareRFQ {
  id: number;
  link: string;
}
@Component({
  selector: 'dialog_share_rfqview',
  templateUrl: './dialog_share_rfqview.html',
})

export class DialogShareRFQViewDialog {
     
  currentUser: User;

  constructor(
    public dialogRef: MatDialogRef<DialogShareRFQViewDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogShareRFQ,
    private authenticationService: AuthenticationService,
    public toastr: ToastrManager,) { 
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x); 
    }  

  onCancelClick(): void {
    this.dialogRef.close();
  }  

}