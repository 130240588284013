import { Component } from '@angular/core';

@Component({
  selector: 'app-shri-engineers',
  templateUrl: './shri-engineers.component.html',
  styleUrls: ['./shri-engineers.component.scss']
})
export class ShriEngineersComponent {

}
