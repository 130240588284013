import { Component } from '@angular/core';

@Component({
  selector: 'app-aadveek-enterprises',
  templateUrl: './aadveek-enterprises.component.html',
  styleUrls: ['./aadveek-enterprises.component.scss']
})
export class AadveekEnterprisesComponent {

}
