import { Component } from '@angular/core';

@Component({
  selector: 'app-hi-tech-enterprises',
  templateUrl: './hi-tech-enterprises.component.html',
  styleUrls: ['./hi-tech-enterprises.component.scss']
})
export class HiTechEnterprisesComponent {

}
