import { Component } from '@angular/core';

@Component({
  selector: 'app-shantdeep-metals-pvt-ltd',
  templateUrl: './shantdeep-metals-pvt-ltd.component.html',
  styleUrls: ['./shantdeep-metals-pvt-ltd.component.scss']
})
export class ShantdeepMetalsPvtLtdComponent {

}
