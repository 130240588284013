import { Component } from '@angular/core';

@Component({
  selector: 'app-accutech-laser-pvt-ltd',
  templateUrl: './accutech-laser-pvt-ltd.component.html',
  styleUrls: ['./accutech-laser-pvt-ltd.component.scss']
})
export class AccutechLaserPvtLtdComponent {

}
