import { Component } from '@angular/core';

@Component({
  selector: 'app-perfect-tools-and-gauges',
  templateUrl: './perfect-tools-and-gauges.component.html',
  styleUrls: ['./perfect-tools-and-gauges.component.scss']
})
export class PerfectToolsAndGaugesComponent {

}
