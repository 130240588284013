import { Component } from '@angular/core';

@Component({
  selector: 'app-p-and-p-enterprises',
  templateUrl: './p-and-p-enterprises.component.html',
  styleUrls: ['./p-and-p-enterprises.component.scss']
})
export class PAndPEnterprisesComponent {

}
