import { Component } from '@angular/core';

@Component({
  selector: 'app-dcruz-gears-india-pvt-ltd',
  templateUrl: './dcruz-gears-india-pvt-ltd.component.html',
  styleUrls: ['./dcruz-gears-india-pvt-ltd.component.scss']
})
export class DcruzGearsIndiaPvtLtdComponent {

}
