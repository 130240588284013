
  <section>
    <!-- Site footer -->
    <footer class="site-footer">
		<div class="container">
		  <div class="row">
			<div class="col-sm-12 col-md-6">
			  <h6>About</h6>
			  <p>Get Your Vendor is a powerful platform that empowers manufacturing businesses of all sizes. With its user-friendly interface, vendors and buyers can connect, collaborate, and build a strong network that fosters growth and success. The platform's domain expertise is readily available to help guide you through the sourcing process, enabling you to achieve your goals and reach your full potential....<a routerLink="/aboutus">Read more</a></p>
			</div>
  
			<div class="col-xs-6 col-md-3">
			  <h6>Quick Links</h6>
			  <ul class="footer-links">
				<li><a routerLink="/blog">Blogs</a></li>
				<li><a routerLink="/strategicpartner">Partner Program</a></li>
				<li><a routerLink="/career">Career</a></li>
				<li><a routerLink="/faq">FAQ's</a></li>
				<li><a routerLink="/privacy-policy">Privacy Policy</a></li>
				<li><a routerLink="/terms">Terms of Use</a></li>
			  </ul>
			</div>
  
			<div class="col-xs-6 col-md-3">
			  <h6>Company</h6>
			  <ul class="footer-links">
				<li><a routerLink="/aboutus">About Us</a></li>
				<li><a routerLink="/contactus">Contact Us</a></li>
				<li><a routerLink="/vendors-benefits">Vendor</a></li>
				<li><a routerLink="/buyers-benefits">Buyer</a></li>
				<li><a routerLink="/testimonials">Testimonials</a></li>
				<li><a href="sitemap.xml">Sitemap</a></li>
			  </ul>
			</div>
		  </div>
		  <hr>
		</div>
		<div class="container">
		  <div class="row">
			<div class="col-md-8 col-sm-6 col-lg-8">
			  <p class="copyright-text">Copyright &copy; 2024 All Rights Reserved by 
		   Indisourcing Solutions Pvt Ltd.
			  </p>
			</div>
  
			<div class="col-md-4 col-sm-6 col-lg-4">
			  <ul class="social-icons">
				<li><a class="facebook" href="https://www.facebook.com/gettyourvendor" target="_blank" title="Follow us on Facebook"><i class="fa fa-facebook"></i></a></li>
				<li><a class="twitter" href="https://twitter.com/GetYourVendor" target="_blank" title="Follow us on Twitter"><i class="fa fa-twitter"></i></a></li>
				<!-- <li><a class="twitter" href="https://twitter.com/GetYourVendor" target="_blank" title="Follow us on Twitter"><i class="fa-brands fa-x-twitter"></i></a></li> -->
				<li><a class="instagram" href="https://www.instagram.com/getyourvendor/" target="_blank" title="Follow us on Instagram"><i class="fa fa-instagram"></i></a></li>
				<li><a class="linkedin" href="https://www.linkedin.com/company/get-your-vendor"  target="_blank" title="Follow us on LinkedIn"><i class="fa fa-linkedin"></i></a></li> 
				<li><a class="pinterest" href="https://in.pinterest.com/getyourvendor0101" target="_blank" title="Follow us on Pinterest"><i class="fa fa-pinterest"></i></a></li>   
			  </ul>
			</div>
		  </div>
		</div>
		<div>
			<a href="#top" id="back-to-top"><i class="fa fa-long-arrow-up"></i></a>	
			
			
		</div>
		
  </footer>
  <!-- <div id="mybutton1">
-- <button class="feedback">Request Demo</button> -->
	<!-- <button (click)="openDialog()" class="btn btn-info" >Schedule a Call Back!</button>	 -->
  <!-- </div> -->



</section>	
<!-- Back to top -->

