import { Component } from '@angular/core';

@Component({
  selector: 'app-suyash-gauges-and-instruments',
  templateUrl: './suyash-gauges-and-instruments.component.html',
  styleUrls: ['./suyash-gauges-and-instruments.component.scss']
})
export class SuyashGaugesAndInstrumentsComponent {

}
