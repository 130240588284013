import { Component } from '@angular/core';

@Component({
  selector: 'app-sri-velu-enterprises',
  templateUrl: './sri-velu-enterprises.component.html',
  styleUrls: ['./sri-velu-enterprises.component.scss']
})
export class SriVeluEnterprisesComponent {

}
