import { Component } from '@angular/core';

@Component({
  selector: 'app-omkar-enterprises',
  templateUrl: './omkar-enterprises.component.html',
  styleUrls: ['./omkar-enterprises.component.scss']
})
export class OmkarEnterprisesComponent {

}
