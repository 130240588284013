import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(private title: Title, @Inject(DOCUMENT) private dom, private meta:Meta) { }

  updateCanonicalUrl(url:string){
   const head = this.dom.getElementsByTagName('head')[0];
   var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
   if (element==null) {
     element= this.dom.createElement('link') as HTMLLinkElement;
     head.appendChild(element);
   }
   element.setAttribute('rel','canonical')
   element.setAttribute('href',url)
 }


 updateTitle(title: string){
   this.title.setTitle(title);
 }

 updateMetaTags(metaTags: MetaDefinition[]){
   metaTags.forEach(m=> this.meta.updateTag(m));
 }
}
