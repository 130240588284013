import { Component } from '@angular/core';

@Component({
  selector: 'app-tauro-steel-products',
  templateUrl: './tauro-steel-products.component.html',
  styleUrls: ['./tauro-steel-products.component.scss']
})
export class TauroSteelProductsComponent {

}
