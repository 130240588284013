import { Component } from '@angular/core';

@Component({
  selector: 'app-kalpak-precision-industries',
  templateUrl: './kalpak-precision-industries.component.html',
  styleUrls: ['./kalpak-precision-industries.component.scss']
})
export class KalpakPrecisionIndustriesComponent {

}
