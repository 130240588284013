import { Component } from '@angular/core';

@Component({
  selector: 'app-kedar-precision-components',
  templateUrl: './kedar-precision-components.component.html',
  styleUrls: ['./kedar-precision-components.component.scss']
})
export class KedarPrecisionComponentsComponent {

}
