import { Component } from '@angular/core';

@Component({
  selector: 'app-rameshwar-enterprises',
  templateUrl: './rameshwar-enterprises.component.html',
  styleUrls: ['./rameshwar-enterprises.component.scss']
})
export class RameshwarEnterprisesComponent {

}
