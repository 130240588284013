import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

(window as any)
  .$("body")
  .on("click", '[data-toggle="sidebar"]', function (e) {
    let side_bar = document.getElementsByClassName("app");
  //  console.log(side_bar);
    side_bar[0].classList.toggle("sidenav-toggled");
  });

// (window as any)
//   .$("body")
//   .on("click", '[data-toggle="slide"]', function (e) {
//     let side_bar = document.getElementsByClassName("side-menu__item");
//     side_bar[0].parentElement.classList.toggle("is-expanded");
//   });