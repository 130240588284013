import { Component } from '@angular/core';

@Component({
  selector: 'app-marsh-precision-engineering',
  templateUrl: './marsh-precision-engineering.component.html',
  styleUrls: ['./marsh-precision-engineering.component.scss']
})
export class MarshPrecisionEngineeringComponent {

}
