import { Component } from '@angular/core';

@Component({
  selector: 'app-vaibhav-manufacturing-solutions-pvt-ltd',
  templateUrl: './vaibhav-manufacturing-solutions-pvt-ltd.component.html',
  styleUrls: ['./vaibhav-manufacturing-solutions-pvt-ltd.component.scss']
})
export class VaibhavManufacturingSolutionsPvtLtdComponent {

}
