import { Component } from '@angular/core';

@Component({
  selector: 'app-royal-engineering-and-solution',
  templateUrl: './royal-engineering-and-solution.component.html',
  styleUrls: ['./royal-engineering-and-solution.component.scss']
})
export class RoyalEngineeringAndSolutionComponent {

}
