import { Component } from '@angular/core';

@Component({
  selector: 'app-raks-aeroengineering',
  templateUrl: './raks-aeroengineering.component.html',
  styleUrls: ['./raks-aeroengineering.component.scss']
})
export class RaksAeroengineeringComponent {

}
