import { Component } from '@angular/core';

@Component({
  selector: 'app-rixon-fasteners-pvt-ltd',
  templateUrl: './rixon-fasteners-pvt-ltd.component.html',
  styleUrls: ['./rixon-fasteners-pvt-ltd.component.scss']
})
export class RixonFastenersPvtLtdComponent {

}
