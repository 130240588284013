import { Component } from '@angular/core';

@Component({
  selector: 'app-alumina-india-casting-company',
  templateUrl: './alumina-india-casting-company.component.html',
  styleUrls: ['./alumina-india-casting-company.component.scss']
})
export class AluminaIndiaCastingCompanyComponent {

}
