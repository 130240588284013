import { Component } from '@angular/core';

@Component({
  selector: 'app-avn-enterprises',
  templateUrl: './avn-enterprises.component.html',
  styleUrls: ['./avn-enterprises.component.scss']
})
export class AvnEnterprisesComponent {

}
