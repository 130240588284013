import { Component } from '@angular/core';

@Component({
  selector: 'app-square-engineering',
  templateUrl: './square-engineering.component.html',
  styleUrls: ['./square-engineering.component.scss']
})
export class SquareEngineeringComponent {

}
