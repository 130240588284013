import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { NgModule,  CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AutocompleteLibModule} from 'angular-ng-autocomplete';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ng6-toastr-notifications';
import { ContactusComponent } from './contactus/contactus.component';
import { PublicRFQComponent } from './public_rfq/public_rfq.component';
import { CareerComponent } from './career/career.component';
import { BlogComponent } from './blog/blog.component';
import { FaqComponent } from './faq/faq.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import { LocationStrategy } from '@angular/common';
import { ForgotpassComponent } from './forgotpass/forgotpass.component';
import { HelpComponent } from './help/help.component';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FormsModule } from '@angular/forms';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { HomeModule } from './home/home.module';
import { ForbuyerComponent } from './forbuyer/forbuyer.component';
import { ForvendorComponent } from './forvendor/forvendor.component';
import { CustomfgComponent } from './blog/customfg/customfg.component';
import { VdsComponent } from './blog/vds/vds.component';
import { CofounderComponent } from './blog/cofounder/cofounder.component';
import { SupplychainComponent } from './blog/supplychain/supplychain.component';
import { Budget2021Component } from './blog/budget2021/budget2021.component';
import { GyvbComponent } from './blog/gyvb/gyvb.component';
import { BehindsComponent } from './blog/behinds/behinds.component';
import { DynamicScriptsService } from './service/dynamic-scripts.service';
import { ManufacturingComponent } from './blog/manufacturing/manufacturing.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SiddharthengineeringComponent } from './vendors/siddharthengineering/siddharthengineering.component';
// import { NewSignupFormComponent } from './home/new-signup-form/new-signup-form.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
// import { NewSigninFormComponent } from './home/new-signin-form/new-signin-form.component';
import {MatCheckboxModule} from '@angular/material/checkbox'
import {MatInputModule} from '@angular/material/input';
import { ProcurementComponent } from './blog/procurement/procurement.component';
import { NewPricingPageComponent } from './new-pricing-page/new-pricing-page.component';
import { PricingLeadComponent } from './new-pricing-page/new-pricing-page.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { DialogShareRFQViewDialog } from './service/msg_dialog/msgdialog.service';
import { ActWarningService } from './service/act_warning.service';
import { MatSliderModule } from '@angular/material/slider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';



@NgModule({
  declarations: [
    AppComponent,
    ContactusComponent,
    PublicRFQComponent,
    CareerComponent,
    BlogComponent,
    FaqComponent,
    AboutusComponent,
    ForgotpassComponent,
    HelpComponent,
    TermsComponent,
    PrivacyComponent,
    ForbuyerComponent,
    ForvendorComponent,
    CustomfgComponent,
    VdsComponent,
    CofounderComponent,
    SupplychainComponent,
    Budget2021Component,
    GyvbComponent,
    BehindsComponent,
    ManufacturingComponent,
    SiddharthengineeringComponent,
    ProcurementComponent,
    NewPricingPageComponent,
    PricingLeadComponent,
    DialogShareRFQViewDialog,
  
    //ActWarningService
   
    
  

  ],
  imports: [
    BrowserModule,
    NgxSkeletonLoaderModule,
    CommonModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FormsModule,
    ToastrModule.forRoot(),
    TypeaheadModule.forRoot(),
    AutocompleteLibModule,
    HttpClientModule,
    HomeModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule, 
    MatInputModule,
    ShareButtonsModule,
    ShareIconsModule,
    MatSliderModule,
    MatFormFieldModule,
    MatDialogModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}, Title, DynamicScriptsService, Meta, ActWarningService],
  bootstrap: [AppComponent]
})
export class AppModule { }
