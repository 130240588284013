import { Component } from '@angular/core';

@Component({
  selector: 'app-ghanshyam-machining',
  templateUrl: './ghanshyam-machining.component.html',
  styleUrls: ['./ghanshyam-machining.component.scss']
})
export class GhanshyamMachiningComponent {

}
