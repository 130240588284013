import { Component } from '@angular/core';

@Component({
  selector: 'app-savitri-enterprises',
  templateUrl: './savitri-enterprises.component.html',
  styleUrls: ['./savitri-enterprises.component.scss']
})
export class SavitriEnterprisesComponent {

}
