import { Component } from '@angular/core';

@Component({
  selector: 'app-power-control-electro-systems-pvt-ltd',
  templateUrl: './power-control-electro-systems-pvt-ltd.component.html',
  styleUrls: ['./power-control-electro-systems-pvt-ltd.component.scss']
})
export class PowerControlElectroSystemsPvtLtdComponent {

}
